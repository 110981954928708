import { AppBar, Toolbar, Container } from '@mui/material'
import { useParams } from 'react-router-dom'

import { NavLink, NavBrand, Search } from '../'
import { useCart } from '../../../context/CartContext'

import styles from './Header.module.css'

function WaiterHeader() {
  const { userName } = useParams()

  const { cartQuantity } = useCart()

  return (
    <AppBar className={styles.navbar}>
      <Container>
        <Toolbar className={styles.toolbar}>
          <NavBrand
            href={`/${userName}/tables`}
            imgSrc="/images/white_logo.webp"
            alt="Logo"
          />
          <Search className={styles.search} />
          <div className={styles.downOnMobile}>
            <NavLink
              href={`/${userName}/tables`}
              imgSrc="/images/home.svg"
              text="Home"
            />
            <NavLink
              href={`/${userName}/orders`}
              imgSrc="/images/food-orders.svg"
              text="Orders"
            />
            <NavLink href={`/${userName}/cart`} imgSrc="/images/cart.svg">
              <div style={{ fontSize: '12px', paddingRight: '3px' }}>
                <span className={styles.dot}>{cartQuantity}</span>
              </div>
            </NavLink>
          </div>
          <div className={styles.logoutContainer}>
            <NavLink
              href={`/logout`}
              imgSrc="/images/log-out-outline.svg"
              text="Logout"
            />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default WaiterHeader
