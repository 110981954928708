import { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import CustomSelect from '../Select/CustomSelect'
import { getAllCategories } from '../../../../services/Requests'
import {
  deleteSubCategory,
  moveProductsToAnotherCategory
} from '../../../../services/AdminActions'
import { ENDPOINTS } from '../../../../utils/entities/urls'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

import styles from './CategoriesAlertDialog.module.css'

const CategoriesAlertDialog = ({ open, handleClose, handleAction }) => {
  const axiosPrivate = useAxiosPrivate()
  const { userName, smallCategory } = useParams()
  const navigate = useNavigate()
  const [subCategories, setSubCategories] = useState(null)

  const { handleSubmit, control } = useForm()

  const actionAndClose = () => {
    handleClose()
    handleAction()
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getAllCategories(axiosPrivate)
        setSubCategories(categories)
      } catch (error) {
        console.log('Error fetching categories', error)
      }
    }

    fetchCategories()
  }, [])

  const onSubmit = async ({ category }) => {
    await moveProductsToAnotherCategory(axiosPrivate, smallCategory, category)
    await deleteSubCategory(axiosPrivate, smallCategory)
    handleClose()
    navigate(ENDPOINTS.manageCategories(userName))
  }

  const selectOptions = subCategories?.reduce((acc, item) => {
    if (item.smallCategory !== smallCategory) {
      acc.push({
        label: item.smallCategory,
        value: item.smallCategory
      })
    }
    return acc
  }, [])

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          Delete category and associated products?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you rather want to move the products to an existing category
            please select the new category from the dropdown below and click
            `Move`.
          </DialogContentText>
          <form
            className={styles.formWrapper}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CustomSelect
              className={styles.select}
              options={selectOptions}
              control={control}
              name="category"
              label="New Category"
              isRequired
            />
            <Button
              className={styles.moveBtn}
              type="submit"
              variant="contained"
            >
              Move
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={actionAndClose} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CategoriesAlertDialog
