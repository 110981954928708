import React from 'react'
import { createRoot } from 'react-dom/client'

import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { ThemeProvider } from '@emotion/react'
import { StyledEngineProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'

import { mainTheme } from './themes/mainTheme'
import App from './App'

import './main.css'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={mainTheme}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)
