import { useNavigate } from 'react-router-dom'
import { Container, Button, Typography } from '@mui/material'

const NotFound = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <Container
      sx={{
        position: 'fixed',
        top: '100px',
        left: 0,
        right: 0,
        bottom: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}
      >
        404 Not Found
      </Typography>
      <Typography sx={{ mt: 2 }}>
        The page you are looking for does not exist.
      </Typography>
      <Button variant="contained" onClick={goBack} sx={{ mt: 2 }}>
        Go Back
      </Button>
    </Container>
  )
}

export default NotFound
