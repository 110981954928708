import React, { useEffect, useState } from 'react'

import {
  IconButton,
  Typography,
  TextField,
  Autocomplete,
  Collapse
} from '@mui/material'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import classNames from 'classnames'

import styles from './ProductsHeader.module.css'

const stockOptions = ['In Stock', 'Out of Stock']

function ProductsHeader({
  setOrderByParam,
  setFilterByParam,
  categoriesOptions
}) {
  const [orderBy, setOrderBy] = useState({ criteria: 'name', order: false })
  const [filterBy, setFilterBy] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  const handleSortChange = (criteria) => {
    setOrderBy((prevState) => ({
      criteria,
      order: criteria === prevState.criteria ? !prevState.order : false
    }))
  }

  const handleFilterChange = (criteria, filterValue) => {
    setFilterBy((prevFilters) => {
      const newFilters = prevFilters.filter((f) => f.criteria !== criteria)
      if (filterValue) {
        newFilters.push({ criteria, selected: filterValue })
      }
      return newFilters
    })
  }

  const toggleShowFilters = () => {
    setShowFilters(!showFilters)
  }

  useEffect(() => {
    setOrderByParam(orderBy)
    setFilterByParam(filterBy)
  }, [orderBy, filterBy])

  return (
    <div className={styles.productsHeaderContainer}>
      <div className={styles.productsHeader}>
        <IconButton
          onClick={() => handleSortChange('name')}
          className={classNames(styles.nameButton, {
            [styles.lastPressed]: orderBy.criteria === 'name'
          })}
        >
          {orderBy.criteria === 'name' &&
            (orderBy.order ? (
              <NorthIcon fontSize="small" className={styles.icon} />
            ) : (
              <SouthIcon fontSize="small" className={styles.icon} />
            ))}
          <Typography className={styles.text}>Name</Typography>
        </IconButton>
        <IconButton
          onClick={() => handleSortChange('category')}
          className={classNames(styles.categoryButton, {
            [styles.lastPressed]: orderBy.criteria === 'category'
          })}
        >
          {orderBy.criteria === 'category' &&
            (orderBy.order ? (
              <NorthIcon fontSize="small" className={styles.icon} />
            ) : (
              <SouthIcon fontSize="small" className={styles.icon} />
            ))}
          <Typography className={styles.text}>Category</Typography>
        </IconButton>
        <IconButton
          onClick={() => handleSortChange('stock')}
          className={classNames(styles.stockButton, {
            [styles.lastPressed]: orderBy.criteria === 'stock'
          })}
        >
          {orderBy.criteria === 'stock' &&
            (orderBy.order ? (
              <NorthIcon fontSize="small" className={styles.icon} />
            ) : (
              <SouthIcon fontSize="small" className={styles.icon} />
            ))}
          <Typography className={styles.text}>Stock</Typography>
        </IconButton>
        <IconButton onClick={toggleShowFilters} className={styles.filterButton}>
          {showFilters ? (
            <FilterAltOffIcon fontSize="small" className={styles.icon} />
          ) : (
            <FilterAltIcon fontSize="small" className={styles.icon} />
          )}
        </IconButton>
      </div>
      <Collapse in={showFilters}>
        <div className={styles.filtersContainer}>
          <Autocomplete
            className={styles.autocomplete}
            options={categoriesOptions}
            onChange={(event, newValue) =>
              handleFilterChange('category', newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Filter by category" />
            )}
          />
          <Autocomplete
            className={styles.autocomplete}
            options={stockOptions}
            onChange={(event, newValue) =>
              handleFilterChange('stock', newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Filter by stock" />
            )}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default ProductsHeader
