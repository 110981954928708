import { Breadcrumbs, Link, Typography, Container } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'

import styles from './NavBreadcrumbs.module.css'

const nameMap = {
  tables: 'Tables',
  orders: 'Orders',
  food: 'Food',
  drinks: 'Drinks',
  cart: 'Cart'
}

const isOnlyDigits = (str) => {
  return /^\d+$/.test(str)
}

const WaiterBreadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x)
    .map((pathname) => decodeURIComponent(pathname))

  const homeUrl = `${pathnames[0] + '/tables'}`

  return (
    <Container className={styles.breadcrumbs}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to={homeUrl}>
          Home
        </Link>
        {pathnames.map((value, index) => {
          const isUsername = index === 0
          if (isUsername) {
            return null
          }

          if (value === 'tables') {
            return null
          }

          // TODO: Fix this
          if (isOnlyDigits(value)) {
            value = 'Categories'
          }

          const name = nameMap[value] || value
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          return last ? (
            <Typography key={to}>{name}</Typography>
          ) : (
            <Link component={RouterLink} key={to} to={to}>
              {name}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Container>
  )
}

export default WaiterBreadcrumbs
