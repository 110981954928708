import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'

import { getUserById } from '../../../services/Requests'
import { Loading } from '../../common'
import { updateUser, deleteUser } from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Roles from '../../../utils/enums/roles'
import {
  FormContainer,
  ImageUpload,
  CustomRadioGroup,
  CustomTextField,
  FormButton
} from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: 'Waiter', value: Roles.WAITER },
  { label: 'Kitchen', value: Roles.KITCHEN }
]

function AdminEditUser() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const { userName, employeeId } = useParams()
  const [isLoading, setLoading] = useState({
    edit: false,
    delete: false
  })

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setError: setFieldError,
    clearErrors
  } = useForm()

  const password = watch('password')
  const confirmPassword = watch('cpassword')

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setFieldError('cpassword', {
        type: 'manual',
        message: 'Passwords do not match'
      })
    } else {
      clearErrors('cpassword')
    }
  }, [password, confirmPassword, setFieldError, clearErrors])

  const onSubmit = async ({ name, password, role, image }) => {
    try {
      setLoading({ ...isLoading, edit: true })
      await updateUser(axiosPrivate, user, name, password, role, image)
      showSuccess('User updated successfully')
      navigate(ENDPOINTS.usersList(userName))
    } catch (error) {
      showError('Action failed. Please try again!')
    } finally {
      setLoading({ ...isLoading, edit: false })
    }
  }

  const handleDelete = async () => {
    try {
      setLoading({ ...isLoading, delete: true })
      await deleteUser(axiosPrivate, user._id)
      showSuccess('User deleted successfully')
      navigate(ENDPOINTS.usersList(userName))
    } catch (error) {
      showError('Action failed. Please try again!')
    } finally {
      setLoading({ ...isLoading, delete: false })
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getUserById(axiosPrivate, employeeId)
        reset({
          name: user.name,
          role: user.position
        })
        setUser(user)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchUser()
  }, [])

  if (user === null) {
    return <Loading />
  }
  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Edit user
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="name"
            label="Name"
            isRequired
          />

          <CustomTextField
            type="password"
            control={control}
            name="password"
            label="New Password"
            rules={{
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long'
              }
            }}
          />

          <CustomTextField
            type="password"
            control={control}
            name="cpassword"
            label="Confirm New Password"
          />

          <CustomRadioGroup
            control={control}
            name="role"
            label="Role"
            options={radioOptions}
            row
            isRequired
          />

          <ImageUpload
            control={control}
            name="image"
            initialImage={user.image}
          />

          <FormButton isLoading={isLoading.edit} type={ButtonType.EDIT}>
            <Typography variant="button">Edit user</Typography>
          </FormButton>
        </Stack>
      </form>
      <FormButton
        isLoading={isLoading.delete}
        type={ButtonType.DELETE}
        onClick={() => handleDelete(user._id)}
      >
        <Typography variant="button">Delete user</Typography>
      </FormButton>
    </FormContainer>
  )
}

export default AdminEditUser
