import { Link } from 'react-router-dom'

function NavBrand({ href, imgSrc, alt, children }) {
  return (
    <Link
      to={href}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img src={imgSrc} alt={alt} width="90px" height="auto" />
      {children}
    </Link>
  )
}

export default NavBrand
