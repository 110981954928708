import { useState, useEffect, useMemo } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import {
  Container,
  Box,
  Button,
  Typography,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import TuneIcon from '@mui/icons-material/Tune'

import { getProducts } from '../../../services/AdminActions'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { AdminProductsHeader } from '../../common/ListsHeaders'
import { Loading, NoContent } from '../../common'

import styles from './AdminProductsList.module.css'

function AdminProductsList() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { userName } = useParams()
  const [products, setProducts] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [orderBy, setOrderBy] = useState({})
  const [filterBy, setFilterBy] = useState({})

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await getProducts(axiosPrivate)
        setProducts(products)
        const sortedProducts = sortProducts(products, orderBy)
        const filteredProducts = filterProducts(sortedProducts, filterBy)
        setFilteredProducts(filteredProducts)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchProducts()
  }, [orderBy, filterBy])

  const categoriesOptions = useMemo(() => {
    if (products) {
      return [...new Set(products.map((product) => product.category))]
    }
    return []
  }, [products])

  const sortProducts = (products, orderBy) => {
    const productsCopy = [...products]
    return productsCopy.sort((a, b) => {
      if (orderBy?.criteria === 'name') {
        return orderBy.order === false
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else if (orderBy?.criteria === 'category') {
        return orderBy.order === false
          ? a.category.localeCompare(b.category)
          : b.category.localeCompare(a.category)
      } else if (orderBy?.criteria === 'price') {
        return orderBy.order === false ? a.price - b.price : b.price - a.price
      }
      return 0
    })
  }

  const filterProducts = (products, filterBy) => {
    if (filterBy?.selected !== null) {
      if (filterBy?.criteria === 'category') {
        return products.filter(
          (product) => product.category === filterBy?.selected
        )
      } else {
        return products
      }
    } else {
      return products
    }
  }

  if (products === null) {
    return <Loading />
  }

  return (
    <Container className={styles.container}>
      <Box display={'flex'} gap={1} mt={2}>
        <Button
          variant="contained"
          onClick={() => navigate(`/${userName}/products-list/add-new-product`)}
          startIcon={<AddIcon />}
          sx={{ px: 1.2 }}
        >
          <Typography variant="button">Add new product</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={styles.categoriesBtn}
          onClick={() => navigate(`/${userName}/manage-categories`)}
          startIcon={<TuneIcon />}
          sx={{ px: 1.2 }}
        >
          <Typography variant="button">Manage Categories</Typography>
        </Button>
      </Box>
      {products?.length ? (
        <>
          <AdminProductsHeader
            setOrderByParam={setOrderBy}
            setFilterByParam={setFilterBy}
            categoriesOptions={categoriesOptions}
          />
          <List sx={{ pt: 0 }}>
            {filteredProducts.map((value, index) => {
              const labelId = `product-label-${index}`

              return (
                <ListItemButton
                  sx={{
                    backgroundColor: 'background.paper',
                    py: 1,
                    mt: 1,
                    borderRadius: 4
                  }}
                  key={value._id}
                  onClick={() =>
                    navigate(`/${userName}/products-list/${value._id}`)
                  }
                >
                  <Box display={'flex'} width={'100%'} alignItems={'center'}>
                    <Box width={'50%'}>
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          overflow: 'clip'
                        }}
                        id={labelId}
                        primary={value.name}
                      />
                    </Box>
                    <Box width={'30%'}>{value.category}</Box>
                    <Box width={'15%'}>{value.price} lei</Box>

                    <Box>
                      <img
                        className="arrow"
                        src="/images/right-arrow.svg"
                        alt=""
                      ></img>
                    </Box>
                  </Box>
                </ListItemButton>
              )
            })}
          </List>
        </>
      ) : (
        <NoContent text={'There are no products in the database'} />
      )}
    </Container>
  )
}

export default AdminProductsList
