import React, { useState, useEffect } from 'react'

import DownloadIcon from '@mui/icons-material/Download'
import { Button, Typography } from '@mui/material'

const InstallPWAButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault()
      setDeferredPrompt(e)
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
    }
  }, [])

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log(
          `User ${
            choiceResult.outcome === 'accepted' ? 'accepted' : 'dismissed'
          } the install prompt`
        )
        setDeferredPrompt(null)
      })
    }
  }

  return deferredPrompt ? (
    <Button
      variant="contained"
      color="secondary"
      sx={{ px: 4, borderRadius: '1rem', color: 'white' }}
      type="submit"
      endIcon={<DownloadIcon />}
      onClick={handleInstallClick}
    >
      <Typography variant="button">Install PWA</Typography>
    </Button>
  ) : null
}

export default InstallPWAButton
