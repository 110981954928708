import { useState, useEffect, useMemo } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import {
  Container,
  Box,
  Button,
  Typography,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { getAllCategories } from '../../../services/Requests'
import { Loading, NoContent } from '../../common'
import { AdminCategoriesHeader } from '../../common/ListsHeaders'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import styles from './AdminManageCategories.module.css'

function AdminManageCategories() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { userName } = useParams()
  const [categories, setCategories] = useState(null)
  const [filteredCategories, setFilteredCategories] = useState([])
  const [orderBy, setOrderBy] = useState({})
  const [filterBy, setFilterBy] = useState({})

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getAllCategories(axiosPrivate)
        setCategories(categories)
        const sortedCategories = sortCategories(categories, orderBy)
        const filteredCategories = filterCategories(sortedCategories, filterBy)
        setFilteredCategories(filteredCategories)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchCategories()
  }, [orderBy, filterBy])

  const categoriesOptions = useMemo(() => {
    if (categories) {
      return [...new Set(categories.map((category) => category.bigCategory))]
    }
    return []
  }, [categories])

  const sortCategories = (categories, orderBy) => {
    const categoriesCopy = [...categories]
    return categoriesCopy.sort((a, b) => {
      if (orderBy?.criteria === 'subcategory') {
        return orderBy.order === false
          ? a.smallCategory.localeCompare(b.smallCategory)
          : b.smallCategory.localeCompare(a.smallCategory)
      } else if (orderBy?.criteria === 'category') {
        return orderBy.order === false
          ? a.bigCategory.localeCompare(b.bigCategory)
          : b.bigCategory.localeCompare(a.bigCategory)
      }
      return 0
    })
  }

  const filterCategories = (categories, filterBy) => {
    if (filterBy?.selected !== null) {
      if (filterBy?.criteria === 'category') {
        return categories.filter(
          (product) => product.bigCategory === filterBy?.selected
        )
      } else {
        return categories
      }
    } else {
      return categories
    }
  }

  if (categories === null) {
    return <Loading />
  }

  return (
    <Container className={styles.container}>
      <Box display={'flex'} mt={4}>
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/${userName}/manage-categories/add-new-category`)
          }
          startIcon={<AddIcon />}
        >
          <Typography variant="button">Add new category</Typography>
        </Button>
      </Box>
      {categories?.length ? (
        <>
          <AdminCategoriesHeader
            setOrderByParam={setOrderBy}
            setFilterByParam={setFilterBy}
            categoriesOptions={categoriesOptions}
          />
          <List sx={{ pt: 0 }}>
            {filteredCategories.map((value, index) => {
              const labelId = `category-label-${index}`
              return (
                <ListItemButton
                  sx={{
                    backgroundColor: 'background.paper',
                    py: 1,
                    mt: 1,
                    borderRadius: 4
                  }}
                  key={value._id}
                  onClick={() =>
                    navigate(
                      `/${userName}/manage-categories/${value.smallCategory}`
                    )
                  }
                >
                  <Box
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box width={'50%'}>
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          overflow: 'clip'
                        }}
                        id={labelId}
                        primary={value.smallCategory}
                      />
                    </Box>
                    <Box width={'30%'}>{value.bigCategory}</Box>
                    <Box>
                      <img
                        className="arrow"
                        src="/images/right-arrow.svg"
                        alt=""
                      ></img>
                    </Box>
                  </Box>
                </ListItemButton>
              )
            })}
          </List>
        </>
      ) : (
        <NoContent text={'There are no subcategories in the database'} />
      )}
    </Container>
  )
}

export default AdminManageCategories
