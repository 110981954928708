import { useAuth } from '../context/AuthContext'
import { axiosPublic } from '../services/axiosActions/axiosActions'
import { ENDPOINTS } from '../utils/entities/urls'

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    try {
      const response = await axiosPublic.get(ENDPOINTS.refresh())
      setAuth((prev) => {
        return {
          ...prev,
          user: response.data.user,
          accessToken: response.data.accessToken
        }
      })
      return response.data.accessToken
    } catch (error) {
      setAuth({})
    }
  }
  return refresh
}

export default useRefreshToken
