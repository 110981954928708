import { ENDPOINTS } from '../utils/entities/urls'

export const getTables = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.tables())
    return response.data.tables
  } catch (error) {
    console.error('Error getting the tables', error)
    throw error
  }
}

export const getPublicTables = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.tablesStatus())
    return response.data.tables
  } catch (error) {
    console.error('Error getting the tables', error)
    throw error
  }
}

export const getAllOrders = async (
  axiosInstance,
  { period, user, orderStatus }
) => {
  try {
    const params = {}

    if (period) {
      params.period = period
    }

    if (user) {
      params.user = user
    }
    if (
      orderStatus &&
      (orderStatus === 'active' || orderStatus === 'inactive')
    ) {
      params.status = orderStatus
    }
    const response = await axiosInstance.get(ENDPOINTS.orders(), { params })
    return response.data.orders
  } catch (error) {
    console.error('Error getting the orders', error)
    throw error
  }
}

export const getAllCategories = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.category())
    return response.data.categories
  } catch (error) {
    console.error('Error getting the categories', error)
    throw error
  }
}

export const getParticularCategory = async (axiosInstance, subCategory) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.categoryId(subCategory))
    return response.data.category
  } catch (error) {
    console.error('Error getting the category', error)
    throw error
  }
}

export const getOrdersByWaiterName = async (axiosInstance, waiterName) => {
  try {
    const period = 'P1D'
    const response = await axiosInstance.get(
      ENDPOINTS.ordersByWaiter(waiterName),
      { params: { period } }
    )
    return response.data.orders
  } catch (error) {
    console.error('Error getting the orders', error)
    throw error
  }
}

export const getActiveOrders = async (
  axiosInstance,
  page = 1,
  pageSize = 6
) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.activeOrders(), {
      params: { page, pageSize }
    })
    return response.data
  } catch (error) {
    console.error('Error getting the active orders', error)
    throw error
  }
}

export const getProduct = async (axiosInstance, productId) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.product(productId))
    return response.data.product
  } catch (error) {
    console.error('Error getting the product', error)
    throw error
  }
}

export const getParticularProduct = async (
  axiosInstance,
  productId,
  category,
  subCategory
) => {
  try {
    const response = await axiosInstance.get(
      ENDPOINTS.productWithValidation(productId, category, subCategory)
    )
    return response.data.product
  } catch (error) {
    console.error('Error getting the product', error)
    throw error
  }
}

export const getUserById = async (axiosInstance, userId) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.user(userId))
    return response.data.user
  } catch (error) {
    console.error('Error getting the user', error)
    throw error
  }
}

export const getUserByName = async (axiosInstance, username) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.userByUsername(username))
    return response.data.user
  } catch (error) {
    console.error('Error getting the user', error)
    throw error
  }
}

export const getSubCategories = async (axiosInstance, category) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.subCategories(category))
    return response.data.subCategories
  } catch (error) {
    console.error('Error getting the food categories', error)
    throw error
  }
}

export const getProductsInCategory = async (
  axiosInstance,
  category,
  subCategory
) => {
  try {
    const response = await axiosInstance.get(
      ENDPOINTS.productsInCategory(category, subCategory)
    )
    return response.data.products
  } catch (error) {
    console.error('Error getting the products in category', error)
    throw error
  }
}

export const login = async (axiosInstance, name, password) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.login(), {
      name,
      password
    })
    return response.data
  } catch (error) {
    console.error('Error logging in', error)
    throw error
  }
}

export const logout = async (axiosInstance) => {
  try {
    await axiosInstance.get(ENDPOINTS.logout())
  } catch (error) {
    console.error('Error logging out', error)
    throw error
  }
}
