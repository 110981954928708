import { ENDPOINTS } from '../utils/entities/urls'
import OrderedProductStatus from '../utils/enums/orderedProductStatus'

export const changeProductStatus = async (
  axiosInstance,
  order,
  productId,
  currentStatus
) => {
  try {
    const tempOrder = order
    const newStatus = getNewStatus(currentStatus)
    const productFromOrder = tempOrder.products.find(
      (product) => product._id === productId
    )
    productFromOrder.status = newStatus
    const response = await axiosInstance.patch(
      ENDPOINTS.orderId(order._id),
      tempOrder
    )
    return response.data.updatedOrder
  } catch (e) {
    console.log('Error updating order product status: ', e)
    throw e
  }
}

export const changeProductStock = async (axiosInstance, productId, inStock) => {
  try {
    const response = await axiosInstance.patch(
      ENDPOINTS.updateProductStock(productId),
      {
        inStock
      }
    )
    return response.data.updatedProduct
  } catch (error) {
    console.error('Error changing product stock: ', error)
    throw error
  }
}

const getNewStatus = (currentStatus) => {
  const enumKeys = Object.keys(OrderedProductStatus)
  const enumValues = Object.values(OrderedProductStatus)
  const currentIndex = enumValues.indexOf(currentStatus)
  const nextIndex = (currentIndex + 1) % enumKeys.length
  const nextStatus = OrderedProductStatus[enumKeys[nextIndex]]
  return nextStatus
}
