import { Link, useParams } from 'react-router-dom'
import { Container, IconButton, Box, Grid, Typography } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useQuery } from '@tanstack/react-query'

import { Loading, NoContent } from '../../common'
import { getProductsInCategory } from '../../../services/Requests'
import { useCart } from '../../../context/CartContext'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { capitalizeString } from '../../../utils/functions/stringUtils'
import { useToast } from '../../../hooks/useToast'
import { getImageSrc } from '../../../utils/functions/images'

import styles from './SubCategoryProducts.module.css'

function SubCategoryProducts() {
  const axiosPrivate = useAxiosPrivate()
  const { increaseCartQuantity, decreaseCartQuantity } = useCart()
  const { userName, tableNr, category, subcategory } = useParams()
  const { showError } = useToast()

  const capitalizedCategory = capitalizeString(category)
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['products', subcategory],
    queryFn: () =>
      getProductsInCategory(axiosPrivate, capitalizedCategory, subcategory)
  })

  const handleIncreaseCartQuantity = (product, productComment, tableNr) => {
    try {
      increaseCartQuantity(product, productComment, tableNr)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const handleDecreaseCartQuantity = (product, productComment, tableNr) => {
    try {
      decreaseCartQuantity(product, productComment, tableNr)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }
  if (data?.length === 0) {
    return <NoContent text={'There are no products for this subcategory'} />
  }

  return (
    <Container className={styles.container}>
      <Grid container spacing={2}>
        {data.map((product) => {
          return (
            <Grid item lg={3} md={4} sm={4} xs={6} key={product._id}>
              <Box className={styles.card}>
                {!product.inStock ? (
                  <>
                    <div className={styles.cardContainer}>
                      <img
                        className={styles.cardImg}
                        src={getImageSrc(product.image)}
                      />
                      {!product.inStock && (
                        <div className={styles.overlay}>Out of Stock</div>
                      )}
                    </div>
                    <Box style={{ paddingBottom: '0.3rem' }}>
                      <Typography
                        pt={2}
                        pb={1}
                        component={'h2'}
                        variant="h5"
                        textAlign={'center'}
                        fontWeight={'500'}
                        fontSize={'1.3rem'}
                      >
                        {product.name}
                      </Typography>
                      <Typography textAlign={'center'}>
                        {product.price} LEI
                      </Typography>
                    </Box>{' '}
                  </>
                ) : (
                  <Link
                    to={`/${userName}/tables/${tableNr}/${category}/${subcategory}/${product._id}`}
                  >
                    <img
                      className={styles.cardImg}
                      src={getImageSrc(product.image)}
                    />
                    <Box style={{ paddingBottom: '0.3rem' }}>
                      <Typography
                        pt={2}
                        pb={1}
                        component={'h2'}
                        variant="h5"
                        textAlign={'center'}
                        fontWeight={'500'}
                        fontSize={'1.3rem'}
                      >
                        {product.name}
                      </Typography>
                      <Typography textAlign={'center'} fontWeight={'500'}>
                        {product.price} LEI
                      </Typography>
                    </Box>
                  </Link>
                )}
                {product.inStock ? (
                  <div className={styles.cardActions}>
                    <IconButton
                      aria-label="decrease"
                      size="large"
                      onClick={() =>
                        handleDecreaseCartQuantity(product, '', tableNr)
                      }
                    >
                      <RemoveCircleIcon color="navbar" fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="increase"
                      size="large"
                      onClick={() =>
                        handleIncreaseCartQuantity(product, '', tableNr)
                      }
                    >
                      <AddCircleIcon color="navbar" fontSize="inherit" />
                    </IconButton>
                  </div>
                ) : (
                  <div className={styles.cardActions}></div>
                )}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default SubCategoryProducts
