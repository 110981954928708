import { createTheme } from '@mui/material/styles'

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#C00033'
    },
    secondary: {
      main: '#DE8135'
    },
    navbar: {
      main: '#1a1a1a'
    }
  }
})
