import { AppBar, Toolbar, Container } from '@mui/material'
import { useParams } from 'react-router-dom'

import { NavLink, NavBrand } from '../'

import styles from './Header.module.css'

function KitchenHeader() {
  const { userName } = useParams()
  return (
    <AppBar className={styles.navbar}>
      <Container>
        <Toolbar className={styles.toolbar}>
          <NavBrand
            href={`/${userName}/kitchen/main-page`}
            imgSrc="/images/white_logo.webp"
            alt="Logo"
          />
          <div className={styles.downOnMobile}>
            <NavLink
              href={`/${userName}/kitchen/main-page`}
              imgSrc="/images/home.svg"
              text="Home"
            />
            <NavLink
              href={`/${userName}/kitchen/orders`}
              imgSrc="/images/food-orders.svg"
              text="Orders"
            />
            <NavLink
              href={`/${userName}/kitchen/products`}
              imgSrc="/images/products-white.svg"
              text="Products"
            />
          </div>
          <div className={styles.logoutContainer}>
            <NavLink
              href={`/logout`}
              imgSrc="/images/log-out-outline.svg"
              text="Logout"
            />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default KitchenHeader
