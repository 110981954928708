import React from 'react'

import { Button, LinearProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import ButtonType from '../../../../utils/enums/buttonType'

import styles from './FormButton.module.css'

const getButtonConfig = (type) => {
  switch (type) {
    case ButtonType.ADD:
      return { color: 'primary', icon: <AddIcon /> }
    case ButtonType.EDIT:
      return { color: 'primary', icon: <EditIcon /> }
    case ButtonType.DELETE:
      return { color: 'secondary', icon: <DeleteIcon /> }
    default:
      return { color: 'primary', icon: null }
  }
}

const FormButton = ({ isLoading, type, children, ...rest }) => {
  const { color, icon } = getButtonConfig(type)
  return (
    <div className={styles.stacked}>
      <Button
        className={styles.btn}
        variant="contained"
        color={color}
        type="submit"
        startIcon={icon}
        {...rest}
      >
        {children}
      </Button>
      {isLoading && (
        <LinearProgress className={styles.btnLoading} color={color} />
      )}
    </div>
  )
}

export default FormButton
