import { useEffect, useState } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../../context/AuthContext'

function Logout() {
  const { logoutAction } = useAuth()
  const location = useLocation()
  const from = location.state?.from?.pathname || ''

  const [isDone, setIsDone] = useState(false)

  useEffect(() => {
    const logout = async () => {
      try {
        await logoutAction()

        setIsDone(true)
      } catch (error) {
        setIsDone(true)
      }
    }
    logout()
  }, [])
  return isDone && <Navigate to="/login" state={{ from }} replace />
}
export default Logout
