import {
  AdminActionsPage,
  AdminAddNewCategory,
  AdminAddNewUser,
  AdminAddNewProduct,
  AdminEditCategory,
  AdminEditProduct,
  AdminEditUser,
  AdminManageCategories,
  AdminTablesList,
  AdminOrders,
  AdminProductsList,
  AdminProfile,
  AdminUsersList
} from '../../components/admin-pages'
import {
  KitchenOrders,
  MainPage,
  Product as KitchenProduct
} from '../../components/kitchen'
import ProductsList from '../../components/kitchen/ProductList/ProductList'
import {
  Category,
  Cart,
  SubCategories,
  Tables,
  Orders,
  Product,
  SubCategoryProducts
} from '../../components/waiter-pages'
import Roles from '../enums/roles'

export const routes = [
  {
    path: '/:userName/tables',
    components: [Tables],
    role: Roles.WAITER
  },
  {
    path: '/:userName/orders',
    components: [Orders],
    role: Roles.WAITER
  },
  {
    path: '/:userName/tables/:tableNr',
    components: [Category],
    role: Roles.WAITER
  },

  {
    path: '/:userName/tables/:tableNr/:category',
    components: [SubCategories],
    role: Roles.WAITER
  },
  {
    path: '/:userName/tables/:tableNr/:category/:subcategory',
    components: [SubCategoryProducts],
    role: Roles.WAITER
  },
  {
    path: '/:userName/tables/:tableNr/:category/:subcategory/:productId',
    components: [Product],
    role: Roles.WAITER
  },
  {
    path: '/:userName/cart',
    components: [Cart],
    role: Roles.WAITER
  },
  {
    path: '/:userName/kitchen/main-page',
    components: [MainPage],
    role: Roles.KITCHEN
  },
  {
    path: '/:userName/kitchen/orders',
    components: [KitchenOrders],
    role: Roles.KITCHEN
  },
  {
    path: '/:userName/kitchen/products',
    components: [ProductsList],
    role: Roles.KITCHEN
  },
  {
    path: '/:userName/kitchen/products/:productId',
    components: [KitchenProduct],
    role: Roles.KITCHEN
  },
  {
    path: '/:userName/admin-actions-page',
    components: [AdminActionsPage],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/admin-profile',
    components: [AdminProfile],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/admin-orders',
    components: [AdminOrders],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/users-list',
    components: [AdminUsersList],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/products-list',
    components: [AdminProductsList],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/tables-list',
    components: [AdminTablesList],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/products-list/add-new-product',
    components: [AdminAddNewProduct],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/manage-categories',
    components: [AdminManageCategories],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/manage-categories/add-new-category',
    components: [AdminAddNewCategory],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/users-list/:employeeId',
    components: [AdminEditUser],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/users-list/add-new-user',
    components: [AdminAddNewUser],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/products-list/:productId',
    components: [AdminEditProduct],
    role: Roles.ADMIN
  },
  {
    path: '/:userName/manage-categories/:smallCategory',
    components: [AdminEditCategory],
    role: Roles.ADMIN
  }
]
