import { Container, Button, Typography, IconButton } from '@mui/material'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import classNames from 'classnames'
import { useMutation } from '@tanstack/react-query'

import {
  getOrderedProductStatusImage,
  returnPrimaryOrderWaiter,
  returnSecondaryOrderWaiters,
  parseOrderTimeAndDate
} from '../../../utils/functions/orders'
import OrderStatus from '../../../utils/enums/orderedProductStatus'
import { changeProductStatus } from '../../../services/KitchenActions'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

import styles from './OrderModal.module.css'

function OrderModal({
  order,
  orderViewMode,
  toggleOrderViewMode,
  handleFreeTable
}) {
  const axiosPrivate = useAxiosPrivate()
  const { showError } = useToast()

  const handleFreeTableClick = async () => {
    try {
      handleFreeTable(order)
      toggleOrderViewMode()
    } catch (error) {
      console.error('Error freeing table: ', error)
      throw error
    }
  }

  const markAsDeliveredMutation = useMutation({
    mutationFn: ({ order, productId, productStatus }) =>
      changeProductStatus(axiosPrivate, order, productId, productStatus),
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const markAsDelivered = async (order, productId, productStatus) => {
    try {
      await markAsDeliveredMutation.mutateAsync({
        order,
        productId,
        productStatus
      })
    } catch (error) {
      console.error('Error updating order product status: ', error)
      throw error
    }
  }

  return (
    <Container className={styles.container}>
      <div
        className={classNames(styles.orderCard, {
          [styles.activeOrder]: order?.activeOrder,
          [styles.inactiveOrder]: !order?.activeOrder
        })}
      >
        <div className={styles.content}>
          <div className={styles.orderHeader}>
            <h5 className={styles.title}>Table {order.table}</h5>
            <IconButton
              type="button"
              size="small"
              onClick={() => toggleOrderViewMode()}
              className={styles.orderViewModeBtn}
            >
              {!orderViewMode ? (
                <OpenInFullIcon color="primary" fontSize="small" />
              ) : (
                <CloseFullscreenIcon color="primary" fontSize="small" />
              )}
            </IconButton>
          </div>
          <div className={styles.orderTime}>
            <div className={styles.createdTime}>
              <span className={styles.orderTimeText}>Created:</span>
              <p className={styles.orderTimeText}>
                {parseOrderTimeAndDate(order.createdAt, 'default')}
              </p>
            </div>
            {order?.closedAt && (
              <div className={styles.closedTime}>
                <span className={styles.orderTimeText}>Closed:</span>
                <p className={styles.orderTimeText}>
                  {parseOrderTimeAndDate(order.closedAt, 'default')}
                </p>
              </div>
            )}
          </div>
          <div className={styles.waiters}>
            {returnPrimaryOrderWaiter(order)}
            {returnSecondaryOrderWaiters(order)}
          </div>
          <div className={classNames(styles.products, styles.scrollbar)}>
            {order?.products.map((product, index) => {
              return (
                <div key={index} className={styles.product}>
                  <div className={styles.orderedProduct}>
                    <img
                      src={getOrderedProductStatusImage(product.status)}
                      alt={`${product.status}`}
                    />
                    <div className={styles.productInfo}>
                      <p className={styles.productName}>{product.name}</p>
                      {product.status === OrderStatus.DONE && (
                        <div className={styles.markAsDelivered}>
                          <IconButton
                            type="button"
                            size="small"
                            className={styles.markAsDeliveredBtn}
                            onClick={() =>
                              markAsDelivered(
                                order,
                                product._id,
                                product.status
                              )
                            }
                          >
                            <TaskAltIcon color="success" fontSize="medium" />
                          </IconButton>
                        </div>
                      )}
                      {product.status === OrderStatus.DELIVERED && (
                        <p className={styles.delivered}>Delivered</p>
                      )}
                      <p className={styles.productQuantity}>
                        {product.quantity}
                      </p>
                    </div>
                  </div>
                  {product.comment && (
                    <p className={styles.comment}>{product.comment}</p>
                  )}
                </div>
              )
            })}
          </div>
          <h6 className={styles.totalPrice}>Total: {order.totalPrice} LEI</h6>
          {order?.activeOrder === true && (
            <Button
              variant="contained"
              color="secondary"
              className={styles.freeTableBtn}
              onClick={() => handleFreeTableClick()}
            >
              <Typography variant="button">Free table</Typography>
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}

export default OrderModal
