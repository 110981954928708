import { Outlet } from 'react-router-dom'

import AdminHeader from '../components/common/Header/AdminHeader'
import AdminBreadcrumbs from '../components/common/NavBreadcrumbs/AdminBreadcrumbs'

const AdminLayout = () => {
  return (
    <>
      <AdminHeader />
      <AdminBreadcrumbs />
      <Outlet />
    </>
  )
}

export default AdminLayout
