import { Container, useMediaQuery, useTheme } from '@mui/material'

import styles from './FormContainer.module.css'

const FormContainer = ({ children }) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Container
      className={styles.container}
      maxWidth={isLargeScreen ? 'sm' : 'xs'}
      sx={{
        mt: isLargeScreen ? '4rem' : '2rem'
      }}
    >
      {children}
    </Container>
  )
}

export default FormContainer
