import React from 'react'

import { IconButton, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import NextPlanIcon from '@mui/icons-material/NextPlan'
import classNames from 'classnames'

import { changeProductStatus } from '../../../services/KitchenActions'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import OrderedProductStatus from '../../../utils/enums/orderedProductStatus'
import { useToast } from '../../../hooks/useToast'

import styles from './OrderedProduct.module.css'

const OrderedProduct = ({ order, product }) => {
  const axiosPrivate = useAxiosPrivate()
  const { showError } = useToast()

  const setNextProductStatusMutation = useMutation({
    mutationFn: ({ order, productId, productStatus }) =>
      changeProductStatus(axiosPrivate, order, productId, productStatus),
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const setNextProductStatus = async (order, productId, productStatus) => {
    try {
      await setNextProductStatusMutation.mutateAsync({
        order,
        productId,
        productStatus
      })
    } catch (error) {
      console.error('Error updating order product status: ', error)
      throw error
    }
  }

  return (
    <div className={styles.productCard}>
      <div
        className={classNames(styles.statusContainer, [
          styles[`status${product?.status}`]
        ])}
      ></div>
      <div className={styles.productInfoContainer}>
        <div className={styles.productInfo}>
          <Typography variant="p" className={styles.quantity}>
            {product.quantity}
          </Typography>
          <Typography variant="span" className={styles.quantitySpan}>
            x
          </Typography>

          <Typography variant="p" className={styles.productName}>
            {product.name}
          </Typography>
        </div>
        {product.comment && (
          <div className={styles.commentContainer}>
            <Typography variant="p" className={styles.comment}>
              {product.comment}
            </Typography>
          </div>
        )}
      </div>
      {product.status !== OrderedProductStatus.DONE &&
        product.status !== OrderedProductStatus.DELIVERED && (
          <IconButton
            type="button"
            className={styles.btn}
            onClick={() =>
              setNextProductStatus(order, product._id, product.status)
            }
          >
            <NextPlanIcon fontSize="medium" />
          </IconButton>
        )}
    </div>
  )
}

export default OrderedProduct
