import axios from 'axios'

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://serveflowbe.axaro.ro/api/v1'
    : 'http://localhost:5000/api/v1'

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
})

export default axiosPrivate
