import TableStatus from '../utils/enums/tableStatus'
import { ENDPOINTS } from '../utils/entities/urls'
import OrderedProductStatus from '../utils/enums/orderedProductStatus'
import Categories from '../utils/enums/categories'

export const placeOrder = async (axiosInstance, cartEntry, username, total) => {
  const newOrder = {
    user: username,
    table: cartEntry.table,
    activeOrder: true,
    products: cartEntry.items,
    totalPrice: total
  }

  try {
    newOrder.products.forEach((product) => {
      if (product.category === Categories.DRINKS) {
        product.status = OrderedProductStatus.DONE
      }
    })
    await axiosInstance.post(ENDPOINTS.orders(), newOrder)
    await axiosInstance.patch(
      ENDPOINTS.updateTableStatus(parseInt(cartEntry.table)),
      {
        tableStatus: TableStatus.OCCUPIED
      }
    )
  } catch (error) {
    console.error('Error placing order: ', error)
    throw error
  }
}

export const addToActiveOrder = async (
  axiosInstance,
  cartEntry,
  username,
  total
) => {
  try {
    const response = await axiosInstance.get(
      ENDPOINTS.activeOrderForTable(parseInt(cartEntry.table))
    )
    const patchData = {
      products: [...cartEntry.items],
      totalPrice: total,
      user: username
    }
    patchData.products.forEach((product) => {
      if (product.category === Categories.DRINKS) {
        product.status = OrderedProductStatus.DONE
      }
    })
    await axiosInstance.patch(
      ENDPOINTS.addToActiveOrder(response.data.order._id, username),
      patchData
    )
  } catch (error) {
    console.error('Error adding to order: ', error)
    throw error
  }
}

export const freeTable = async (axiosInstance, order) => {
  try {
    const response = await axiosInstance.patch(ENDPOINTS.orderId(order._id), {
      activeOrder: false,
      closedAt: new Date()
    })
    await axiosInstance.patch(
      ENDPOINTS.updateTableStatus(parseInt(order.table)),
      {
        tableStatus: TableStatus.FREE
      }
    )
    return response.data.updatedOrder
  } catch (error) {
    console.error('Error freeing table: ', error)
    throw error
  }
}
