import { Link, useParams } from 'react-router-dom'
import { Container, Box, Grid, Typography } from '@mui/material'

import styles from './Category.module.css'

function Category() {
  const { userName, tableNr } = useParams()

  return (
    <Container className={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12} key={'food'}>
          <Box className={styles.card}>
            <Link to={`/${userName}/tables/${tableNr}/food`}>
              <img className={styles.cardImg} src="/images/food.jpg" />
              <Box>
                <Typography
                  pt={2}
                  pb={2}
                  component={'h2'}
                  variant="h5"
                  textAlign={'center'}
                  fontWeight={'500'}
                  fontSize={'1.3rem'}
                >
                  Food
                </Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item md={6} sm={6} xs={12} key={'drinks'}>
          <Box className={styles.card}>
            <Link to={`/${userName}/tables/${tableNr}/drinks`}>
              <img className={styles.cardImg} src="/images/drinks.jpg" />
              <Box>
                <Typography
                  pt={2}
                  pb={2}
                  component={'h2'}
                  variant="h5"
                  textAlign={'center'}
                  fontWeight={'500'}
                  fontSize={'1.3rem'}
                >
                  Drinks
                </Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Category
