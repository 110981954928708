import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'

import {
  FormContainer,
  ImageUpload,
  CustomRadioGroup,
  CustomTextField,
  FormButton
} from '../../common/CustomFormComponents'
import { getParticularCategory } from '../../../services/Requests'
import { Loading } from '../../common'
import {
  updateSubCategory,
  deleteSubCategory
} from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Categories from '../../../utils/enums/categories'
import CategoriesAlertDialog from '../../common/CustomFormComponents/CategoriesAlertDialog/CategoriesAlertDialog'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: Categories.FOOD, value: Categories.FOOD },
  { label: Categories.DRINKS, value: Categories.DRINKS }
]

function AdminEditCategory() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const navigate = useNavigate()
  const { userName, smallCategory } = useParams()
  const [category, setCategory] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setLoading] = useState({
    edit: false,
    delete: false
  })

  const { handleSubmit, control, reset } = useForm()

  const onSubmit = async ({ smallCategory, bigCategory, image }) => {
    try {
      setLoading({ ...isLoading, edit: true })
      await updateSubCategory(
        axiosPrivate,
        category,
        bigCategory,
        smallCategory,
        image
      )

      showSuccess('Category updated successfully')
      navigate(ENDPOINTS.manageCategories(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading({ ...isLoading, edit: false })
    }
  }

  const handleDelete = async (smallCategory) => {
    try {
      setLoading({ ...isLoading, delete: true })
      await deleteSubCategory(axiosPrivate, smallCategory)
      showSuccess('Category deleted successfully')
      navigate(ENDPOINTS.manageCategories(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading({ ...isLoading, delete: false })
    }
  }

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const category = await getParticularCategory(
          axiosPrivate,
          smallCategory
        )

        reset({
          bigCategory: category.bigCategory,
          smallCategory: category.smallCategory
        })
        setCategory(category)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchCategory()
  }, [])

  if (category === null) {
    return <Loading />
  }
  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Edit category
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="smallCategory"
            label="Category Name"
            isRequired
          />

          <CustomRadioGroup
            control={control}
            name="bigCategory"
            label="Category"
            options={radioOptions}
            row
            isRequired
          />

          <ImageUpload
            control={control}
            name="image"
            initialImage={category.image}
          />

          <FormButton isLoading={isLoading.edit} type={ButtonType.EDIT}>
            <Typography variant="button">Edit category</Typography>
          </FormButton>
        </Stack>
      </form>
      <FormButton
        isLoading={isLoading.delete}
        type={ButtonType.DELETE}
        onClick={() => setIsDialogOpen(true)}
      >
        <Typography variant="button">Delete category</Typography>
      </FormButton>
      <CategoriesAlertDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleAction={() => handleDelete(category.smallCategory)}
      />
    </FormContainer>
  )
}

export default AdminEditCategory
