import { useEffect, useMemo, useState } from 'react'

import {
  Container,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getProducts } from '../../../services/AdminActions'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { ENDPOINTS } from '../../../utils/entities/urls'
import { KitchenProductsHeader } from '../../common/ListsHeaders'
import { NoContent } from '../../common'

function ProductsList() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { userName } = useParams()

  const [filteredProducts, setFilteredProducts] = useState([])
  const [orderBy, setOrderBy] = useState({})
  const [filterBy, setFilterBy] = useState({})

  const {
    isLoading,
    data: products,
    error
  } = useQuery({
    queryKey: [`products`],
    queryFn: () => getProducts(axiosPrivate)
  })

  useEffect(() => {
    if (products) {
      const sortedProducts = sortProducts(products, orderBy)
      const filteredProducts = filterProducts(sortedProducts, filterBy)
      setFilteredProducts(filteredProducts)
    }
  }, [products, orderBy, filterBy])

  const categoriesOptions = useMemo(() => {
    if (products) {
      return [...new Set(products.map((product) => product.category))]
    }
    return []
  }, [products])

  const sortProducts = (products, orderBy) => {
    const productsCopy = [...products]
    return productsCopy.sort((a, b) => {
      if (orderBy?.criteria === 'name') {
        return orderBy.order === false
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else if (orderBy?.criteria === 'category') {
        return orderBy.order === false
          ? a.category.localeCompare(b.category)
          : b.category.localeCompare(a.category)
      } else if (orderBy?.criteria === 'stock') {
        return orderBy?.order === false
          ? (a.inStock ? -1 : 1) - (b.inStock ? -1 : 1)
          : (b.inStock ? -1 : 1) - (a.inStock ? -1 : 1)
      }
      return 0
    })
  }

  const filterProducts = (products, filterBy) => {
    if (filterBy.length) {
      filterBy.forEach((filter) => {
        if (filter.selected !== null) {
          if (filter.criteria === 'category') {
            products = products.filter(
              (product) => product.category === filter.selected
            )
          } else if (filter.criteria === 'stock') {
            products = products.filter(
              (product) => product.inStock === (filter.selected === 'In Stock')
            )
          }
        }
      })
    }

    return products
  }

  const handleClick = (productId) => {
    navigate(ENDPOINTS.kitchenProduct(userName, productId))
  }

  if (isLoading) {
    return null
  }

  if (error) {
    // display generic error page. Please try again later
  }
  if (products?.length === 0) {
    return <NoContent text={'There are no products in the database'} />
  }

  return (
    <Container>
      <KitchenProductsHeader
        setOrderByParam={setOrderBy}
        setFilterByParam={setFilterBy}
        categoriesOptions={categoriesOptions}
      />
      <List sx={{ mb: 6, pt: 0 }} dense>
        {filteredProducts.map((product, index) => {
          const labelId = `checkbox-list-label-${index}`
          return (
            <ListItemButton
              sx={{
                backgroundColor: 'background.paper',
                py: 2,
                px: 2,
                mt: 1,
                borderRadius: 4
              }}
              key={product._id}
              onClick={() => handleClick(product._id)}
            >
              <Box display="flex" width="100%">
                <Box pr={0.5} width="50%">
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      textTransform: 'capitalize',
                      overflow: 'clip'
                    }}
                    id={labelId}
                    primary={product.name}
                  />
                </Box>
                <Box pl={0.5} width="20%">
                  <ListItemText id={labelId} secondary={product.category} />
                </Box>
                <Box pl={0.5}>
                  {!product.inStock && (
                    <Typography
                      sx={{
                        color: 'secondary',
                        fontSize: '0.8rem',
                        marginTop: '4px',
                        marginBottom: '4px'
                      }}
                    >
                      Out of Stock
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display="flex" width="5%">
                <img
                  className="arrow"
                  src="/images/right-arrow.svg"
                  alt=""
                ></img>
              </Box>
            </ListItemButton>
          )
        })}
      </List>
    </Container>
  )
}

export default ProductsList
