import TextField from '@mui/material/TextField'
import { useController } from 'react-hook-form'

import styles from './CustomTextField.module.css'

const CustomTextField = ({
  control,
  name,
  label,
  defaultValue = '',
  disabled,
  isRequired,
  rules,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...rules,
      required: isRequired ? 'This field is required' : undefined
    }
  })

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={invalid}
      helperText={error ? error.message : ''}
      disabled={disabled}
      className={styles.customTextField}
      {...rest}
    />
  )
}

export default CustomTextField
