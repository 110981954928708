import { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Container, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { getAllOrders } from '../../../services/Requests'
import { Loading, NoContent } from '../../common'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import Order from '../Order/Order'

import styles from './Orders.module.css'

function Orders() {
  const axiosPrivate = useAxiosPrivate()
  const { userName } = useParams()
  const [ordersView, setOrdersView] = useState('0')

  const ordersViewOptions = [
    { name: 'Active Orders', value: '0' },
    { name: 'Inactive Orders', value: '1' }
  ]

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['orders', ordersView === '0' ? 'active' : 'inactive', userName],
    queryFn: () =>
      getAllOrders(axiosPrivate, {
        user: userName,
        orderStatus: ordersView === '0' ? 'active' : 'inactive',
        period: 'P1D'
      }),
    refetchInterval: 10000
  })

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }
  if (data?.length === 0) {
    return <NoContent text={'There are no orders managed by this waiter'} />
  }

  return (
    <Container className={styles.container}>
      <div>
        <ToggleButtonGroup
          className={styles.toggleButtonGroup}
          value={ordersView}
          exclusive
          onChange={(e, value) => setOrdersView(value)}
          aria-label="text alignment"
        >
          {ordersViewOptions.map((ordersViewOptions, idx) => (
            <ToggleButton
              key={idx}
              value={ordersViewOptions.value}
              aria-label={ordersViewOptions.name}
              className={styles.toggleButton}
              classes={{
                selected: styles.selectedOrdersViewOption
              }}
            >
              {ordersViewOptions.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <Grid container spacing={3}>
        {data
          .filter((order) =>
            ordersView === '0' ? order?.activeOrder : !order?.activeOrder
          )
          .map((value) => {
            return (
              <Grid item lg={3} md={4} sm={6} xs={6} key={value._id}>
                <Order order={value} />
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}

export default Orders
