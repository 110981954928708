import { useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import useRefreshToken from '../../../hooks/useRefreshToken'
import { useAuth } from '../../../context/AuthContext'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)

    return () => (isMounted = false)
  }, [])

  return <>{isLoading ? null : <Outlet />}</>
}

export default PersistLogin
