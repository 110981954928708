import { useEffect, useState, useCallback } from 'react'

import { Container, Grid, Typography } from '@mui/material'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import PersonIcon from '@mui/icons-material/Person'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant'

import { getActiveOrders } from '../../../services/Requests'
import { Loading, PaginationComponent } from '../../common'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import OrderedProduct from '../OrderedProduct/OrderedProduct'
import { parseOrderTimeAndDate } from '../../../utils/functions/orders'
import Categories from '../../../utils/enums/categories'

import styles from './MainPage.module.css'

function MainPage() {
  const axiosPrivate = useAxiosPrivate()
  const [page, setPage] = useState(1)
  const pageSize = useResponsivePageSize()

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['orders', page, pageSize],
    placeholderData: keepPreviousData,
    queryFn: () => getActiveOrders(axiosPrivate, page, pageSize),
    refetchInterval: 10000
  })

  function useResponsivePageSize() {
    const [pageSize, setPageSize] = useState(getPageSize(window.innerWidth))

    const handleResize = useCallback(() => {
      setPageSize(getPageSize(window.innerWidth))
    }, [])

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])

    return pageSize
  }

  function getPageSize(width) {
    if (width < 600) return 1
    if (width < 900) return 2
    if (width < 1200) return 3
    if (width < 1536) return 4
    return 6
  }

  const handleChange = (e, value) => {
    setPage(value)
  }

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }
  if (data.orders?.length === 0) {
    return (
      <Container className={styles.container}>
        <h1>No active orders.</h1>
      </Container>
    )
  }
  return (
    <Container className={styles.container} maxWidth="false">
      <Grid container spacing={3}>
        {data.orders?.map((order) => {
          return (
            <Grid item key={order._id} xl={2} lg={3} md={4} sm={6} xs={12}>
              <div className={styles.order}>
                <div className={styles.header}>
                  <div className={styles.tableContainer}>
                    <TableRestaurantIcon className={styles.icon} />
                    <Typography variant="p" className={styles.tableText}>
                      Table {order.table}
                    </Typography>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.waiter}>
                      <PersonIcon fontSize="small" className={styles.icon} />
                      <Typography variant="p" className={styles.infoText}>
                        {order.users[0].name}
                      </Typography>
                    </div>
                    <div className={styles.time}>
                      <AccessTimeIcon
                        fontSize="small"
                        className={styles.icon}
                      />
                      <Typography variant="p" className={styles.infoText}>
                        {parseOrderTimeAndDate(order.createdAt, 'time')}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={styles.products}>
                  {order.products
                    .filter((product) => product.category === Categories.FOOD)
                    .map((product, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={index}
                          className={styles.product}
                        >
                          <OrderedProduct order={order} product={product} />
                        </Grid>
                      )
                    })}
                </div>
              </div>
            </Grid>
          )
        })}
      </Grid>
      <PaginationComponent
        page={page}
        totalPages={data.totalPages}
        onPageChange={handleChange}
      />
    </Container>
  )
}

export default MainPage
