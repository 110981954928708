import { Outlet } from 'react-router-dom'

import WaiterHeader from '../components/common/Header/WaiterHeader'
import WaiterBreadcrumbs from '../components/common/NavBreadcrumbs/WaiterBreadcrumbs'

const WaiterLayout = () => {
  return (
    <>
      <WaiterHeader />
      <WaiterBreadcrumbs />
      <Outlet />
    </>
  )
}

export default WaiterLayout
