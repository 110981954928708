import {
  AWS_S3_BUCKET,
  DEFAULT_CATEGORY_IMAGE,
  DEFAULT_PRODUCT_IMAGE,
  DEFAULT_USER_IMAGE,
  IMAGE_PATH_PREFIX
} from '../entities/constants'

const DEFAULT_IMAGES = [
  DEFAULT_USER_IMAGE,
  DEFAULT_PRODUCT_IMAGE,
  DEFAULT_CATEGORY_IMAGE
]

export const getImageSrc = (image) => {
  return DEFAULT_IMAGES.includes(image)
    ? `${IMAGE_PATH_PREFIX}/${image}`
    : `${AWS_S3_BUCKET}/${image}`
}
