import { useState } from 'react'

import { Button, Input, Box, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import { useController } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'

import {
  AWS_S3_BUCKET,
  DEFAULT_CATEGORY_IMAGE,
  DEFAULT_PRODUCT_IMAGE,
  DEFAULT_USER_IMAGE,
  IMAGE_PATH_PREFIX
} from '../../../../utils/entities/constants'

import styles from './ImageUpload.module.css'

const IMAGE_PATHS = {
  [DEFAULT_USER_IMAGE]: `${IMAGE_PATH_PREFIX}/${DEFAULT_USER_IMAGE}`,
  [DEFAULT_PRODUCT_IMAGE]: `${IMAGE_PATH_PREFIX}/${DEFAULT_PRODUCT_IMAGE}`,
  [DEFAULT_CATEGORY_IMAGE]: `${IMAGE_PATH_PREFIX}/${DEFAULT_CATEGORY_IMAGE}`
}

const ImageUpload = ({
  control,
  name,
  label,
  defaultValue = '',
  initialImage = '',
  disabled,
  isRequired,
  ...rest
}) => {
  const {
    field: { onChange }
  } = useController({
    name,
    defaultValue,
    control
  })

  const [imagePreview, setImagePreview] = useState(initialImage)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }

    resizeImage(event.target.files[0])
  }

  const resizeImage = (file) => {
    Resizer.imageFileResizer(
      file,
      640, // max width
      360, // max height
      'webp', // output format
      100, // quality
      0, // rotation
      (uri) => {
        onChange(uri)
      },
      'file' // output type
    )
  }

  const getImagePreviewSrc = (initialImage, imagePreview) => {
    if (IMAGE_PATHS[imagePreview]) {
      return IMAGE_PATHS[imagePreview]
    }
    if (initialImage && imagePreview === initialImage) {
      return `${AWS_S3_BUCKET}/${imagePreview}`
    }
    return imagePreview
  }

  return (
    <div className={styles.container}>
      <Input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        inputProps={{ accept: 'image/*' }}
        {...rest}
      />

      {imagePreview && (
        <img
          src={getImagePreviewSrc(initialImage, imagePreview)}
          alt="Preview"
          className={styles.imagePreview}
        />
      )}
      {!imagePreview && (
        <Box
          className={styles.imagePreviewContainer}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <AddPhotoAlternateOutlinedIcon className={styles.previewIcon} />
          <Typography fontWeight={'500'}>No preview available</Typography>
        </Box>
      )}
      <label htmlFor="file-input">
        <Button
          variant="contained"
          component="span"
          color="secondary"
          startIcon={<CloudUploadIcon />}
          disabled={disabled}
          className={styles.uploadBtn}
        >
          Upload Image
        </Button>
      </label>
    </div>
  )
}

export default ImageUpload
