import { createContext, useContext } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'
import OrderStatus from '../utils/enums/orderedProductStatus'

const CartContext = createContext()

export const useCart = () => {
  return useContext(CartContext)
}

export const CartProvider = ({ children }) => {
  const [cartEntries, setCartEntries] = useLocalStorage('cart', [])

  const cartQuantity = cartEntries.reduce((totalQuantity, cartEntry) => {
    const cartEntryQuantity = cartEntry.items.reduce(
      (cartEntryTotalQuantity, item) => item.quantity + cartEntryTotalQuantity,
      0
    )
    return totalQuantity + cartEntryQuantity
  }, 0)

  const cartTotal = (table) => {
    return cartEntries
      .find((cartEntry) => cartEntry.table === table)
      ?.items?.reduce((total, item) => item?.price * item?.quantity + total, 0)
  }

  const initEntireCart = () => {
    setCartEntries([])
  }
  const initSpecificCart = (table) => {
    setCartEntries((cartEntries) => {
      return cartEntries.filter((cartEntry) => cartEntry.table !== table)
    })
  }

  const getItemQuantity = (product, comment, table) => {
    return (
      cartEntries
        .find((cartEntry) => cartEntry.table === table)
        ?.items?.find(
          (item) => item._id === product._id && item.comment === comment
        )?.quantity || 0
    )
  }

  const increaseCartQuantity = (product, comment, table) => {
    setCartEntries((cartEntries) => {
      const existingTableItem = cartEntries.find(
        (cartEntry) => cartEntry.table === table
      )

      if (!existingTableItem) {
        return addNewItemToCart(cartEntries, product, comment, table)
      }

      return updateExistingItemInCart(cartEntries, product, comment, table)
    })
  }

  const addNewItemToCart = (cartEntries, product, comment, table) => [
    ...cartEntries,
    {
      table,
      items: [createCartItem(product, 1, comment)]
    }
  ]

  const updateExistingItemInCart = (cartEntries, product, comment, table) => {
    return cartEntries.map((cartEntry) => {
      if (cartEntry.table !== table) return cartEntry

      const existingProduct = cartEntry.items.find(
        (prod) => prod._id === product._id && prod.comment === comment
      )

      if (!existingProduct) {
        return {
          ...cartEntry,
          items: [...cartEntry.items, createCartItem(product, 1, comment)]
        }
      }

      return {
        ...cartEntry,
        items: cartEntry.items.map((prod) =>
          prod._id === product._id && prod.comment === comment
            ? { ...prod, quantity: prod.quantity + 1 }
            : prod
        )
      }
    })
  }

  const createCartItem = (product, quantity, comment) => ({
    _id: product._id,
    name: product.name,
    category: product.bigCategory,
    price: product.price,
    status: OrderStatus.ON_HOLD,
    comment,
    quantity
  })

  const adjustCartQuantity = (product, quantity, comment, table) => {
    setCartEntries((cartEntries) => {
      const tableItem = cartEntries.find(
        (cartEntry) => cartEntry.table === table
      )

      if (!tableItem) {
        return addNewProductToCart(
          cartEntries,
          product,
          quantity,
          comment,
          table
        )
      }

      return cartEntries.map((cartEntry) => {
        if (cartEntry.table !== table) return cartEntry

        return updateProductInCart(cartEntry, product, quantity, comment)
      })
    })
  }

  const addNewProductToCart = (
    cartEntries,
    product,
    quantity,
    comment,
    table
  ) => [
    ...cartEntries,
    {
      table,
      items: [createCartItem(product, quantity, comment)]
    }
  ]

  const updateProductInCart = (cartEntry, product, quantity, comment) => {
    const existingProductIndex = cartEntry.items.findIndex(
      (prod) => prod._id === product._id && prod.comment === comment
    )

    if (existingProductIndex === -1) {
      return addProductToExistingTable(cartEntry, product, quantity, comment)
    }

    return {
      ...cartEntry,
      items: cartEntry.items.map((prod, index) =>
        index === existingProductIndex ? { ...prod, quantity } : prod
      )
    }
  }

  const addProductToExistingTable = (
    cartEntry,
    product,
    quantity,
    comment
  ) => ({
    ...cartEntry,
    items: [...cartEntry.items, createCartItem(product, quantity, comment)]
  })

  const decreaseCartQuantity = (product, comment, table) => {
    setCartEntries((cartEntries) => {
      const tableItem = cartEntries.find(
        (cartEntry) => cartEntry.table === table
      )

      if (!tableItem) {
        return cartEntries
      }

      return cartEntries.map((cartEntry) => {
        if (cartEntry.table !== table) return cartEntry

        return updateItemQuantity(cartEntry, product, comment)
      })
    })
  }

  const updateItemQuantity = (cartEntry, product, comment) => {
    const productIndex = cartEntry.items.findIndex(
      (prod) => prod._id === product._id && prod.comment === comment
    )

    if (productIndex === -1) {
      return cartEntry
    }

    const productItem = cartEntry.items[productIndex]

    if (productItem.quantity > 1) {
      return {
        ...cartEntry,
        items: updateProductQuantity(
          cartEntry.items,
          productIndex,
          productItem.quantity - 1
        )
      }
    }

    return removeProductFromCart(cartEntry, productIndex)
  }

  const updateProductQuantity = (items, index, newQuantity) =>
    items.map((item, idx) =>
      idx === index ? { ...item, quantity: newQuantity } : item
    )

  const removeProductFromCart = (cartEntry, productIndex) => ({
    ...cartEntry,
    items: [
      ...cartEntry.items.slice(0, productIndex),
      ...cartEntry.items.slice(productIndex + 1)
    ]
  })

  return (
    <CartContext.Provider
      value={{
        initEntireCart,
        initSpecificCart,
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        adjustCartQuantity,
        cartEntries,
        cartQuantity,
        cartTotal
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
