import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { Stack, Typography, Box } from '@mui/material'

import { getAllCategories } from '../../../services/Requests'
import { Loading } from '../../common'
import { addNewProduct } from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Categories from '../../../utils/enums/categories'
import {
  FormContainer,
  ImageUpload,
  CustomRadioGroup,
  CustomTextField,
  CustomSelect,
  FormButton
} from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: Categories.FOOD, value: Categories.FOOD },
  { label: Categories.DRINKS, value: Categories.DRINKS }
]

function AdminAddNewProduct() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const { handleSubmit, control, setValue } = useForm()
  const navigate = useNavigate()
  const { userName } = useParams()

  const [subCategories, setSubCategories] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const selectedCategory = useWatch({
    control,
    name: 'category'
  })

  const onSubmit = async ({
    name,
    price,
    ingredients,
    weight,
    allergens,
    subCategory,
    image
  }) => {
    try {
      setLoading(true)
      await addNewProduct(
        axiosPrivate,
        name,
        price,
        ingredients,
        weight,
        allergens,
        subCategory,
        image
      )
      showSuccess('Product added successfully')
      navigate(ENDPOINTS.productsList(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading(false)
    }
  }

  const selectOptions = subCategories?.reduce((acc, item) => {
    if (item.bigCategory === selectedCategory) {
      acc.push({
        label: item.smallCategory,
        value: item.smallCategory
      })
    }
    return acc
  }, [])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getAllCategories(axiosPrivate)
        setSubCategories(categories)
      } catch (error) {}
    }

    fetchCategories()
  }, [])

  useEffect(() => {
    setValue('subCategory', '')
  }, [selectedCategory])

  if (subCategories === null) {
    return <Loading />
  }
  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Add new product
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="name"
            label="Name"
            isRequired
          />
          <Box display="flex" gap={2}>
            <CustomTextField
              type="number"
              control={control}
              name="price"
              label="Price"
              isRequired
            />

            <CustomTextField
              control={control}
              name="weight"
              label="Weight"
              placeholder="e.g. 250g"
              isRequired
            />
          </Box>

          <CustomTextField
            control={control}
            name="ingredients"
            label="Ingredients"
            multiline
            rows={2}
            isRequired
          />

          <CustomTextField
            control={control}
            name="allergens"
            label="Allergens"
            multiline
            rows={2}
          />

          <CustomRadioGroup
            control={control}
            name="category"
            label="Category"
            options={radioOptions}
            row
            isRequired
          />

          <CustomSelect
            options={selectOptions}
            control={control}
            name="subCategory"
            label="Subcategory"
            isRequired
            disabled={!selectedCategory}
          />

          <ImageUpload control={control} name="image" />

          <FormButton isLoading={isLoading} type={ButtonType.ADD}>
            <Typography variant="button">Add product</Typography>
          </FormButton>
        </Stack>
      </form>
    </FormContainer>
  )
}

export default AdminAddNewProduct
