import { useParams } from 'react-router-dom'
import { Container, Typography, FormControlLabel, Switch } from '@mui/material'
import classNames from 'classnames'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { Loading } from '../../common'
import { getProduct } from '../../../services/Requests'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { changeProductStock } from '../../../services/KitchenActions'
import { useToast } from '../../../hooks/useToast'
import { getImageSrc } from '../../../utils/functions/images'

import styles from './Product.module.css'

function Product() {
  const axiosPrivate = useAxiosPrivate()
  const { productId } = useParams()
  const queryClient = useQueryClient()
  const { showError } = useToast()

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`product`, productId],
    queryFn: () => getProduct(axiosPrivate, productId),
    staleTime: 2 * 60 * 60 * 1000,
    gcTime: 24 * 60 * 60 * 1000
  })

  const changeStockMutation = useMutation({
    mutationFn: ({ productId, newStockValue }) =>
      changeProductStock(axiosPrivate, productId, newStockValue),
    onSuccess: (updatedProduct) => {
      return queryClient.setQueryData(['product', productId], updatedProduct)
    },
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const handleToggle = async (newStockValue, productId) => {
    try {
      await changeStockMutation.mutateAsync({ productId, newStockValue })
    } catch (error) {
      console.error(error)
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again or error page
    return <div>Try again,{error.message}</div>
  }

  return (
    <Container
      className={classNames(
        styles.container,
        styles.particularProductContainer
      )}
    >
      <div className={styles.productCard}>
        <img src={getImageSrc(data.image)} />
        <div className={styles.productInfo}>
          <div className={styles.productName}>{data.name}</div>
          <div className={styles.productPrice}>{data.price} LEI</div>
        </div>
        <FormControlLabel
          control={
            <Switch
              edge="end"
              onChange={(event) => handleToggle(event.target.checked, data._id)}
              checked={data.inStock}
            />
          }
          label="Stock"
          sx={{ gap: 1, display: 'flex', px: 4 }}
        />
        <div className={styles.productDescription}>
          <Typography variant="p" color="text.secondary">
            <Typography variant="p" color="text.secondary" fontWeight="bold">
              Ingredients:
            </Typography>{' '}
            {data.ingredients}
            {' - '}
            <Typography variant="span" color="text.secondary" fontWeight="bold">
              {data.weight}
            </Typography>
          </Typography>
          {data.allergens && (
            <Typography variant="p" color="text.secondary">
              <Typography variant="p" color="text.secondary" fontWeight="bold">
                Allergens:
              </Typography>{' '}
              {data.allergens}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Product
