import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem
} from '@mui/material'
import { useController } from 'react-hook-form'

import styles from './CustomSelect.module.css'

const CustomSelect = ({
  control,
  name,
  label,
  options,
  defaultValue = '',
  disabled,
  isRequired,
  className,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue,
    rules: isRequired ? { required: 'Please select an option' } : undefined
  })

  return (
    <FormControl
      disabled={disabled}
      fullWidth
      variant="outlined"
      margin="normal"
      error={invalid}
      className={className}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        label={label}
        value={options.some((option) => option.value === value) ? value : ''}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
        className={styles.customSelect}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default CustomSelect
