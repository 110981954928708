import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'

import { addNewUser } from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Roles from '../../../utils/enums/roles'
import {
  FormContainer,
  ImageUpload,
  CustomTextField,
  CustomRadioGroup,
  FormButton
} from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: 'Waiter', value: Roles.WAITER },
  { label: 'Kitchen', value: Roles.KITCHEN }
]

function AdminAddNewUser() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const navigate = useNavigate()
  const { userName } = useParams()
  const [isLoading, setLoading] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    setError: setFieldError,
    clearErrors
  } = useForm()

  const password = watch('password')
  const confirmPassword = watch('cpassword')

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setFieldError('cpassword', {
        type: 'manual',
        message: 'Passwords do not match'
      })
    } else {
      clearErrors('cpassword')
    }
  }, [password, confirmPassword, setFieldError, clearErrors])

  const onSubmit = async ({ name, password, role, image }) => {
    try {
      setLoading(true)
      await addNewUser(axiosPrivate, name, password, role, image)
      showSuccess('User added successfully')
      navigate(ENDPOINTS.usersList(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Add new user
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="name"
            label="Name"
            isRequired
          />
          <CustomTextField
            type="password"
            control={control}
            name="password"
            label="Password"
            isRequired
            rules={{
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long'
              }
            }}
          />
          <CustomTextField
            type="password"
            control={control}
            name="cpassword"
            label="Confirm Password"
            isRequired
          />

          <CustomRadioGroup
            control={control}
            name="role"
            label="Role"
            options={radioOptions}
            row
            isRequired
          />

          <ImageUpload control={control} name="image" />

          <FormButton isLoading={isLoading} type={ButtonType.ADD}>
            <Typography variant="button">Add user</Typography>
          </FormButton>
        </Stack>
      </form>
    </FormContainer>
  )
}

export default AdminAddNewUser
