import { createContext, useContext, useState } from 'react'

import { login, logout } from '../services/Requests'
import axiosInstance from '../services/axiosActions/axiosActions'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})

  const loginAction = async (data) => {
    try {
      const user = await login(axiosInstance, data.username, data.password)
      setAuth(user)
      return user
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const logoutAction = async () => {
    setAuth({})
    await logout(axiosInstance)
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, loginAction, logoutAction }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
