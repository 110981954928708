import OrderedProductStatus from '../enums/orderedProductStatus'
import OrderStatus from '../enums/orderStatus'

export const getOrderedProductStatusImage = (tableStatus) => {
  switch (tableStatus) {
    case OrderedProductStatus.ON_HOLD:
      return '/images/red-circle.svg'
    case OrderedProductStatus.UNDER_PREPARATION:
      return '/images/yellow-circle.svg'
    case OrderedProductStatus.DONE:
      return '/images/green-circle.svg'
    case OrderedProductStatus.DELIVERED:
      return '/images/gray-circle.svg'
    default:
      return null
  }
}

export const getOrderStatusImage = (orderStatus) => {
  switch (orderStatus) {
    case OrderStatus.ACTIVE:
      return '/images/green-circle.svg'
    case OrderStatus.INACTIVE:
      return '/images/yellow-circle.svg'
    default:
      return null
  }
}

export const returnPrimaryOrderWaiter = (order) => {
  return (
    <p
      style={{
        fontWeight: '500',
        margin: '0',
        paddingBottom: '5px',
        fontSize: '15px'
      }}
    >
      {order.users[0].name}
    </p>
  )
}

export const returnSecondaryOrderWaiters = (order) => {
  return order.users.map((user, index) => {
    return (
      <div key={index}>
        {index !== 0 && (
          <p
            style={{
              fontWeight: '400',
              margin: '0',
              fontSize: '15px'
            }}
          >
            {user.name}
          </p>
        )}
      </div>
    )
  })
}

export const parseOrderTimeAndDate = (timeAndDate, format) => {
  const date = new Date(timeAndDate)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  if (format === 'default') {
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    return `${hour}:${minute} ${day}/${month}/${year}`
  } else if (format === 'date') {
    return `${day}/${month}/${year}`
  } else if (format === 'time') {
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    return `${hour}:${minute}`
  } else {
    throw new Error('Invalid format')
  }
}
