import React, { useEffect, useState } from 'react'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { IconButton, TextField } from '@mui/material'

import styles from './NumericUpDown.module.css'

const NumericUpDown = ({
  initialValue,
  onIncrease,
  onDecrease,
  onAdjust,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleIncrease = () => {
    const newValue = value + 1
    setValue(newValue)
    if (onIncrease) {
      onIncrease()
    }
  }

  const handleDecrease = () => {
    const newValue = value - 1
    if (newValue > 0) {
      setValue(newValue)
    }
    if (onDecrease) {
      onDecrease()
    }
  }

  const handleInputChange = (event) => {
    const inputValue =
      !isNaN(parseInt(event.target.value)) && parseInt(event.target.value) > 0
        ? parseInt(event.target.value)
        : initialValue
    setValue(inputValue)
    if (onAdjust) {
      onAdjust(inputValue)
    }
  }

  const resetInputValue = () => {
    setValue('')
  }

  return (
    <div className={styles.container}>
      <IconButton
        className={styles.iconBtn}
        aria-label="decrease"
        size="large"
        onClick={handleDecrease}
      >
        <RemoveCircleIcon color="navbar" fontSize="inherit" />
      </IconButton>
      <TextField
        type="number"
        value={value}
        className={styles.numberInput}
        onBlur={handleInputChange}
        onFocus={resetInputValue}
        onChange={(event) => setValue(parseInt(event.target.value))}
        {...rest}
      />
      <IconButton
        className={styles.iconBtn}
        aria-label="increase"
        size="large"
        onClick={handleIncrease}
      >
        <AddCircleIcon color="navbar" fontSize="inherit" />
      </IconButton>
    </div>
  )
}

export default NumericUpDown
