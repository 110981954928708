import { Container, Typography } from '@mui/material'

const NoContent = ({ text }) => {
  return (
    <Container
      sx={{
        position: 'fixed',
        top: '155px',
        left: 0,
        right: 0,
        bottom: '155px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}
      >
        No Content
      </Typography>
      <Typography sx={{ mt: 2 }}>{text}</Typography>
    </Container>
  )
}

export default NoContent
