import { Route } from 'react-router-dom'

import { routes } from '../entities/routes'

export const extractComponents = (role) => {
  return routes.map(
    (route, index) =>
      route.role === role && (
        <Route
          key={index}
          path={route.path}
          element={route.components.map((Component, index) => {
            return <Component key={index} />
          })}
        />
      )
  )
}
