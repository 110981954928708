import { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import {
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

import Roles from '../../../utils/enums/roles'
import { deleteCart } from '../../../utils/localStorage/cartEntry'
import { useCart } from '../../../context/CartContext'
import { useAuth } from '../../../context/AuthContext'
import { InstallPWAButton } from '../../common'

import styles from './Login.module.css'

const roleHomepageMap = {
  chelner: 'tables',
  bucatarie: 'kitchen/main-page',
  admin: 'admin-actions-page'
}

function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || ''

  const { loginAction } = useAuth()
  const { initEntireCart } = useCart()
  const [loginError, setLoginError] = useState('')
  const [loginCredentials, setLoginCredentials] = useState({
    username: '',
    password: ''
  })
  const [isLoading, setLoading] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const response = await loginAction(loginCredentials)
      const userPosition = response.user.position
      const homepagePath = roleHomepageMap[userPosition]

      if (homepagePath) {
        if (userPosition !== Roles.WAITER) {
          deleteCart()
        } else {
          initEntireCart()
        }
        if (from !== '') {
          navigate(from)
        } else {
          setLoading(false)
          navigate(`/${response.user.name}/${homepagePath}`)
        }
      }
    } catch (error) {
      setLoginError(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      {' '}
      <div className={styles.topHalf}></div>
      <div className={styles.bottomHalf}></div>
      <Container className={styles.container}>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={submitHandler}>
            <div className={styles.logoImage}>
              <a href="/login">
                <img src="./images/dark_logo.webp" alt="logoFundal" />
              </a>
            </div>
            {loginError !== '' && (
              <div className={styles.loginError}>
                Wrong username or password!
              </div>
            )}
            <div className={styles.inputContainer}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon sx={{ color: '#424242' }} />
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentials,
                    username: e.target.value
                  })
                }
                value={loginCredentials.username}
                type="username"
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-input': {
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    },
                    '&:-moz-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    },
                    '&:-ms-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    }
                  }
                }}
                placeholder="Username"
                className={styles.input}
                required
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon sx={{ color: '#424242' }} />
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentials,
                    password: e.target.value
                  })
                }
                value={loginCredentials.password}
                type="password"
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-input': {
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    },
                    '&:-moz-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    },
                    '&:-ms-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px #f0f0f0 inset',
                      '-webkit-text-fill-color': 'black'
                    }
                  }
                }}
                placeholder="Password"
                className={styles.input}
                required
              />
              <div className={styles.btnContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    px: 4,
                    borderRadius: '1rem',
                    color: 'white',
                    width: '100%',
                    textAlign: 'center'
                  }}
                  type="submit"
                >
                  <Typography variant="button">LOGIN</Typography>
                </Button>
                {isLoading && (
                  <LinearProgress
                    className={styles.btnLoading}
                    color="secondary"
                  />
                )}
              </div>
            </div>
          </form>
          <div className={styles.installPWAContainer}>
            <InstallPWAButton />
          </div>
        </div>
      </Container>
    </>
  )
}
export default Login
