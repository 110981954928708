import React from 'react'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Login, Logout } from './components/auth-pages'
import { NotFound, Unauthorized } from './components/common'
import { CartProvider } from './context/CartContext'
import AuthProvider from './context/AuthContext'
import AdminLayout from './layouts/AdminLayout'
import ProtectedRoute from './components/auth-pages/ProtectedComponent/ProtectedRoute'
import Roles from './utils/enums/roles'
import PersistLogin from './components/auth-pages/ProtectedComponent/PersistLogin'
import KitchenLayout from './layouts/KitchenLayout'
import WaiterLayout from './layouts/WaiterLayout'
import { extractComponents } from './utils/functions/components'

import './App.css'

const queryClient = new QueryClient({})

function App() {
  return (
    <main className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <CartProvider>
              <Routes>
                <Route element={<PersistLogin />}>
                  <Route element={<AdminLayout />}>
                    <Route
                      element={<ProtectedRoute allowedRole={Roles.ADMIN} />}
                    >
                      {extractComponents(Roles.ADMIN)}
                    </Route>
                  </Route>

                  <Route element={<KitchenLayout />}>
                    <Route
                      element={<ProtectedRoute allowedRole={Roles.KITCHEN} />}
                    >
                      {extractComponents(Roles.KITCHEN)}
                    </Route>
                  </Route>

                  <Route element={<WaiterLayout />}>
                    <Route
                      element={<ProtectedRoute allowedRole={Roles.WAITER} />}
                    >
                      {extractComponents(Roles.WAITER)}
                    </Route>
                  </Route>
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </CartProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </main>
  )
}

export default App
