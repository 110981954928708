import { useParams, useNavigate } from 'react-router-dom'
import {
  Container,
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  CardMedia
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { getTables } from '../../../services/Requests'
import { Loading } from '../../common'
import { getTableStatusImage } from '../../../utils/functions/tables'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import styles from './Tables.module.css'

function Tables() {
  const axiosPrivate = useAxiosPrivate()
  const { userName } = useParams()
  const navigate = useNavigate()

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['tables'],
    queryFn: () => getTables(axiosPrivate),
    refetchInterval: 10000
  })

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }

  return (
    <Container className={styles.container}>
      <Grid container spacing={2}>
        {data.map((value) => {
          return (
            <Grid item md={3} sm={4} xs={6} key={value._id}>
              <Card sx={{ maxWidth: 345 }} className={styles.card}>
                <CardActionArea
                  onClick={() =>
                    navigate(`/${userName}/tables/${value.tableNr}`)
                  }
                >
                  <CardMedia
                    component="img"
                    height="200"
                    src="/images/table-icon.svg"
                    sx={{
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent>
                    <Typography
                      component={'h2'}
                      variant="h6"
                      textAlign={'center'}
                    >
                      <img
                        style={{ paddingRight: '5px' }}
                        src={getTableStatusImage(value.tableStatus)}
                        alt={`${value.tableStatus}`}
                      />
                      Masa {value.tableNr}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default Tables
