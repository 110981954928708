import { Link, useParams } from 'react-router-dom'
import { Container, Grid, Typography, Box } from '@mui/material'

import styles from './AdminActionsPage.module.css'

function AdminActionsPage() {
  const { userName } = useParams()

  return (
    <Container className={styles.container}>
      <Grid container spacing={2}>
        <Grid item lg={4} md={6} sm={6} xs={6} key={'users'}>
          <Link to={`/${userName}/users-list`}>
            <Box className={styles.card}>
              <img src="/images/users.svg" className={styles.cardImg} />
              <Box>
                <Typography
                  component="h2"
                  fontSize={'1.25rem'}
                  fontWeight={'500'}
                  textAlign={'center'}
                  mt={1}
                >
                  Users list
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={6} key={'products'}>
          <Link to={`/${userName}/products-list`}>
            <Box className={styles.card}>
              <img src="/images/products.svg" className={styles.cardImg} />
              <Box>
                <Typography
                  component="h2"
                  fontSize={'1.25rem'}
                  fontWeight={'500'}
                  mt={1}
                  textAlign={'center'}
                >
                  Products list
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={6} key={'tables'}>
          <Link to={`/${userName}/tables-list`}>
            <Box className={styles.card}>
              <img src="/images/tables.svg" className={styles.cardImg} />
              <Box>
                <Typography
                  component="h2"
                  fontSize={'1.25rem'}
                  fontWeight={'500'}
                  mt={1}
                  textAlign={'center'}
                >
                  Tables list
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AdminActionsPage
