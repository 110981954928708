import { useState, useEffect } from 'react'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { Container, Button, Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { getUsers } from '../../../services/AdminActions'
import { Loading, NoContent } from '../../common'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { getImageSrc } from '../../../utils/functions/images'

import styles from './AdminUsersList.module.css'

function AdminUsersList() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { userName } = useParams()
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getUsers(axiosPrivate)
        setUsers(users)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchUsers()
  }, [])

  if (users === null) {
    return <Loading />
  }

  return (
    <Container>
      <Box display={'flex'} mt={4}>
        <Button
          variant="contained"
          onClick={() => navigate(`/${userName}/users-list/add-new-user`)}
          startIcon={<AddIcon />}
        >
          <Typography variant="button">Add new user</Typography>
        </Button>
      </Box>
      {users?.length ? (
        <ul className={styles.users}>
          {users.map((value) => {
            return (
              <Link to={`/${userName}/users-list/${value._id}`} key={value._id}>
                <li>
                  <img src={getImageSrc(value.image)} alt={value._id} />
                  <div>
                    <Typography
                      component={'h4'}
                      sx={{ fontWeight: '500', fontSize: '1.2rem', mb: 0 }}
                    >
                      {value.name}
                    </Typography>
                    <Typography
                      component={'h5'}
                      sx={{ fontWeight: '500', fontSize: '1.2rem' }}
                    >
                      {value.position}
                    </Typography>
                  </div>
                </li>
              </Link>
            )
          })}
        </ul>
      ) : (
        <NoContent text={'There are no users in the database'} />
      )}
    </Container>
  )
}

export default AdminUsersList
