export const ENDPOINTS = {
  users: () => '/users',
  user: (userId) => `/users/${userId}`,
  userByUsername: (username) => `/users/user/${username}`,
  usersList: (username) => `/${username}/users-list`,

  tables: () => '/tables',
  tableId: (tableId) => `/tables/${tableId}`,
  tablesList: (username) => `/${username}/tables-list`,
  tablesStatus: () => `/tables-status`,
  updateTableStatus: (table) => `/tables/number/${table}`,

  products: () => '/products',
  product: (productId) => `/products/${productId}`,
  kitchenProduct: (username, productId) =>
    `/${username}/kitchen/products/${productId}`,
  productWithValidation: (productId, category, subCategory) =>
    `/products/product/${productId}/${category}/${subCategory}`,
  productsList: (username) => `/${username}/products-list`,
  productsInCategory: (category, subCategory) =>
    `/products/smallCategory/${category}/${subCategory}`,
  updateProductStock: (productId) => `/products/${productId}/stock`,

  category: () => '/category',
  categoryId: (subCategory) => `/category/subcategory/${subCategory}`,
  manageCategories: (username) => `/${username}/manage-categories`,
  subCategories: (category) => `/category/${category}`,
  moveProductsToAnotherCategory: (subCategory) =>
    `/category/subCategory/move/${subCategory}`,

  getUploadUrl: () => '/images/get-upload-url',
  deleteImage: (image) => `/images/delete/${image}`,

  employee: (employeeId) => `/users/employee/${employeeId}`,

  orders: () => '/orders',
  orderId: (orderId) => `/orders/${orderId}`,
  addToActiveOrder: (orderId, username) => `/orders/${orderId}/${username}`,
  ordersByWaiter: (username) => `/orders/users/${username}`,
  activeOrders: () => `/orders/active`,
  activeOrderForTable: (table) => `/orders/active/table/${table}`,

  login: () => '/auth/login',
  logout: () => '/auth/logout',
  refresh: () => '/auth/refresh'
}
