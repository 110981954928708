import { Link } from 'react-router-dom'

import styles from './NavLink.module.css'

function NavLink({ href, imgSrc, text, children }) {
  return (
    <Link to={href} className={styles.link}>
      <img src={imgSrc} alt=""></img>
      <span>{text}</span>
      {children}
    </Link>
  )
}

export default NavLink
