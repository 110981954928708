import { useState } from 'react'

import { useParams } from 'react-router-dom'
import {
  Container,
  Button,
  Collapse,
  IconButton,
  Box,
  Grid,
  Tooltip,
  Typography
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import WarningIcon from '@mui/icons-material/Warning'
import { useMutation, useQuery } from '@tanstack/react-query'

import { placeOrder, addToActiveOrder } from '../../../services/WaiterActions'
import { useCart } from '../../../context/CartContext'
import { Loading, NoContent } from '../../common'
import NumericUpDown from '../../common/NumericUpDown/NumericUpDown'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { getAllOrders } from '../../../services/Requests'
import { useToast } from '../../../hooks/useToast'

import styles from './Cart.module.css'

function Cart() {
  const axiosPrivate = useAxiosPrivate()
  const { userName: username } = useParams()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { showError } = useToast()

  const {
    increaseCartQuantity,
    decreaseCartQuantity,
    adjustCartQuantity,
    cartEntries,
    cartTotal,
    initSpecificCart
  } = useCart()
  const [collapse, setCollapse] = useState(
    Array.from({ length: cartEntries?.length }, (_, index) => index === 0)
  )

  const toggleCollapse = (index) => {
    setCollapse((prevCollapse) => {
      const newCollapse = [...prevCollapse]
      newCollapse[index] = !newCollapse[index]
      return newCollapse
    })
  }

  const handleTooltipClick = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const checkIfTableIsOccupied = (table) => {
    return !!data?.find((o) => o.table === table && o.activeOrder)
  }

  const checkTheOrderOwner = (table) => {
    return (
      data?.find((o) => o.table === table && o.activeOrder)?.users[0].name ===
      username
    )
  }

  const placeOrderMutation = useMutation({
    mutationFn: ({ cartEntry, username, cartTotal }) =>
      placeOrder(axiosPrivate, cartEntry, username, cartTotal),
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })
  const addToActiveOrderMutation = useMutation({
    mutationFn: ({ cartEntry, cartTotal }) =>
      addToActiveOrder(axiosPrivate, cartEntry, username, cartTotal),
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const handlePlaceOrder = async (cartEntry, username, cartTotal) => {
    try {
      await placeOrderMutation.mutateAsync({
        cartEntry,
        username,
        cartTotal
      })
      initSpecificCart(cartEntry.table)
    } catch (error) {
      // Handle error
    }
  }

  const handleAddToActiveOrder = async (cartEntry, username, cartTotal) => {
    try {
      await addToActiveOrderMutation.mutateAsync({
        cartEntry,
        cartTotal
      })
      initSpecificCart(cartEntry.table)
    } catch (error) {
      // Handle error
    }
  }

  const handleIncreaseCartQuantity = (product, productComment, tableNr) => {
    try {
      increaseCartQuantity(product, productComment, tableNr)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const handleDecreaseCartQuantity = (product, productComment, tableNr) => {
    try {
      decreaseCartQuantity(product, productComment, tableNr)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const handleAdjustCartQuantity = (
    product,
    newQuantity,
    productComment,
    tableNr
  ) => {
    try {
      adjustCartQuantity(product, newQuantity, productComment, tableNr)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['orders'],
    queryFn: () =>
      getAllOrders(axiosPrivate, {
        period: 'P1D'
      }),
    refetchInterval: 3000
  })

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }
  if (cartEntries?.length === 0) {
    return <NoContent text={'There are no products in your cart'} />
  }

  return (
    <Container className={styles.container}>
      {cartEntries.map((cartEntry, index) => {
        if (cartEntry.items.length === 0) {
          initSpecificCart(cartEntry.table)
        }
        return (
          <div className={styles.listGroup} key={index}>
            <div className={styles.header}>
              <div className={styles.leftHeader}>
                <IconButton
                  className={styles.clearAll}
                  variant="contained"
                  type="button"
                  onClick={() => initSpecificCart(cartEntry.table)}
                >
                  <DeleteForeverIcon color="primary" />
                </IconButton>
                <h4 className={styles.tableNumber}>Table {cartEntry.table}</h4>
              </div>
              <div className={styles.rightHeader}>
                {checkIfTableIsOccupied(cartEntry.table) &&
                  !checkTheOrderOwner(cartEntry.table) && (
                    <Tooltip
                      title="Another waiter is currently serving this table"
                      arrow
                      open={tooltipOpen}
                    >
                      <IconButton
                        aria-label="warning"
                        size="large"
                        onClick={() => handleTooltipClick()}
                      >
                        <WarningIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
                <IconButton
                  aria-label="delete"
                  size="large"
                  className={styles.collapse}
                  onClick={() => toggleCollapse(index)}
                >
                  {collapse[index] ? (
                    <ExpandLessIcon color="primary" />
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )}
                </IconButton>
              </div>
            </div>
            <Collapse in={collapse[index]}>
              <Box>
                {cartEntry.items.map((item, index) => {
                  return (
                    <Grid className={styles.item} container key={index}>
                      <Grid item xs={4} sm={3} className={styles.mainInfo}>
                        <Typography className={styles.itemTitle}>
                          {item.name}
                        </Typography>
                        {item.comment !== '' && (
                          <Typography className={styles.itemComment}>
                            {item.comment}
                          </Typography>
                        )}
                      </Grid>
                      <Grid className={styles.numericUpDown} item xs={5} sm={6}>
                        <NumericUpDown
                          min={1}
                          initialValue={item.quantity}
                          onIncrease={() =>
                            handleIncreaseCartQuantity(
                              item,
                              item.comment,
                              cartEntry.table
                            )
                          }
                          onDecrease={() =>
                            handleDecreaseCartQuantity(
                              item,
                              item.comment,
                              cartEntry.table
                            )
                          }
                          onAdjust={(quantity) =>
                            handleAdjustCartQuantity(
                              item,
                              quantity,
                              item.comment,
                              cartEntry.table
                            )
                          }
                        />
                      </Grid>
                      <Grid className={styles.itemTitle} item xs={3} sm={3}>
                        <Typography className={styles.itemTitle}>
                          {item.quantity * item.price} lei
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
            </Collapse>
            <div className={styles.itemTotalDetails}>
              <Typography className={styles.itemTotal}>Total</Typography>
              <Typography className={styles.itemTotal}>
                {cartTotal(cartEntry.table)} lei
              </Typography>
            </div>
            <Collapse in={collapse[index]}>
              {!checkIfTableIsOccupied(cartEntry.table) && (
                <Button
                  className={styles.btn}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() =>
                    handlePlaceOrder(
                      cartEntry,
                      username,
                      cartTotal(cartEntry.table)
                    )
                  }
                >
                  Place order
                </Button>
              )}

              {checkIfTableIsOccupied(cartEntry.table) && (
                <Button
                  className={styles.btn}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() =>
                    handleAddToActiveOrder(
                      cartEntry,
                      username,
                      cartTotal(cartEntry.table)
                    )
                  }
                >
                  Add to active order
                </Button>
              )}
            </Collapse>
          </div>
        )
      })}
    </Container>
  )
}

export default Cart
