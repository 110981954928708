import { Breadcrumbs, Link, Typography, Container } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'

import styles from './NavBreadcrumbs.module.css'

const nameMap = {
  'admin-actions-page': 'Actions',
  'users-list': 'Users',
  'add-new-user': 'Add new user',
  'products-list': 'Products',
  'add-new-product': 'Add new product',
  'manage-categories': 'Categories',
  'add-new-category': 'Add new category',
  'tables-list': 'Tables',
  'admin-orders': 'Orders',
  'admin-profile': 'Profile'
}

const AdminBreadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x)
    .map((pathname) => decodeURIComponent(pathname))

  const homeUrl = `${pathnames[0] + '/admin-actions-page'}`

  return (
    <Container className={styles.breadcrumbs}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to={homeUrl}>
          Home
        </Link>
        {pathnames.map((value, index) => {
          const isUsername = index === 0
          if (isUsername) {
            return null
          }

          const name = nameMap[value] || value
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          return last ? (
            <Typography key={to}>{name}</Typography>
          ) : (
            <Link component={RouterLink} key={to} to={to}>
              {name}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Container>
  )
}

export default AdminBreadcrumbs
