import { useEffect, useState } from 'react'

import {
  Container,
  Button,
  Typography,
  Collapse,
  Grid,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'

import { getAllOrders } from '../../../services/Requests'
import { Loading, NoContent } from '../../common'
import {
  getOrderedProductStatusImage,
  returnPrimaryOrderWaiter,
  returnSecondaryOrderWaiters,
  parseOrderTimeAndDate
} from '../../../utils/functions/orders'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import styles from './AdminOrders.module.css'

function Orders() {
  const axiosPrivate = useAxiosPrivate()
  const [ordersView, setOrdersView] = useState('0')

  const ordersViewOptions = [
    { name: 'Active Orders', value: '0' },
    { name: 'Inactive Orders', value: '1' }
  ]

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['orders'],
    queryFn: () => getAllOrders(axiosPrivate, {}),
    refetchInterval: 10000
  })

  const [showMore, setShowMore] = useState({})

  useEffect(() => {
    if (data) {
      const initialShowMoreState = data.reduce((acc, order) => {
        acc[order._id] = false
        return acc
      }, {})
      setShowMore(initialShowMoreState)
    }
  }, [data])

  const toggleShowMore = (orderId) => {
    setShowMore((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId]
    }))
  }

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }
  if (data?.length === 0) {
    return <NoContent text={'There are no orders at the moment'} />
  }

  return (
    <Container className={styles.container}>
      <div className={styles.toggleButtonContainer}>
        <ToggleButtonGroup
          className={styles.toggleButtonGroup}
          value={ordersView}
          exclusive
          onChange={(e, value) => setOrdersView(value)}
          aria-label="text alignment"
        >
          {ordersViewOptions.map((ordersViewOptions, idx) => (
            <ToggleButton
              key={idx}
              value={ordersViewOptions.value}
              aria-label={ordersViewOptions.name}
              className={classNames(styles.toggleButton, {
                [styles.activeOrders]: ordersViewOptions.value === '0',
                [styles.inactiveOrders]: ordersViewOptions.value === '1'
              })}
              classes={{
                selected: styles.selectedOrdersViewOption
              }}
            >
              {ordersViewOptions.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <Grid container spacing={3}>
        {data
          .filter((order) =>
            ordersView === '0' ? order.activeOrder : !order.activeOrder
          )
          .map((value, index) => {
            return (
              <Grid item lg={3} md={4} sm={6} xs={6} key={value._id}>
                <div
                  className={classNames(styles.orderCard, {
                    [styles.activeOrder]: value.activeOrder,
                    [styles.inactiveOrder]: !value.activeOrder
                  })}
                >
                  <div className={styles.content}>
                    <h5 className={styles.title}>Table {value.table}</h5>
                    <div className={styles.orderTime}>
                      <div className={styles.createdTime}>
                        <span className={styles.orderTimeText}>Created:</span>
                        <p className={styles.orderTimeText}>
                          {parseOrderTimeAndDate(value.createdAt, 'default')}
                        </p>
                      </div>
                      {value.closedAt && (
                        <div className={styles.closedTime}>
                          <span className={styles.orderTimeText}>Closed:</span>
                          <p className={styles.orderTimeText}>
                            {parseOrderTimeAndDate(value.closedAt, 'default')}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className={styles.waiters}>
                      <div className={styles.primaryWaiter}>
                        {returnPrimaryOrderWaiter(value)}
                        {value.users.length > 1 && (
                          <Button
                            className={styles.showMoreBtn}
                            onClick={() => toggleShowMore(value._id)}
                          >
                            <Typography
                              className={styles.showMoreText}
                              variant="button"
                            >
                              Show More
                            </Typography>
                          </Button>
                        )}
                      </div>
                      <Collapse in={showMore[value._id]}>
                        {returnSecondaryOrderWaiters(value)}
                      </Collapse>
                    </div>
                    <div
                      className={classNames(styles.products, styles.scrollbar)}
                    >
                      {value.products.map((product, index) => {
                        return (
                          <div key={index} className={styles.product}>
                            <div className={styles.orderedProduct}>
                              <img
                                src={getOrderedProductStatusImage(
                                  product.status
                                )}
                                alt={`${product.status}`}
                              />
                              <div className={styles.productInfo}>
                                <p className={styles.productName}>
                                  {product.name}
                                </p>
                                <p className={styles.productQuantity}>
                                  {product.quantity}
                                </p>
                              </div>
                            </div>
                            {product.comment && (
                              <p className={styles.comment}>
                                {product.comment}
                              </p>
                            )}
                          </div>
                        )
                      })}
                    </div>
                    <h6 className={styles.totalPrice}>
                      Total: {value.totalPrice} LEI
                    </h6>
                  </div>
                </div>
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}

export default Orders
