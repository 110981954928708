import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { IconButton, MenuItem } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { getTables } from '../../../services/Requests'
import { CustomTextField } from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import styles from './TableSelect.module.css'

const TableSelect = ({ setSelectedTable }) => {
  const axiosPrivate = useAxiosPrivate()
  const { tableNr } = useParams()
  const [tables, setTables] = useState([])
  const [openResults, setOpenResults] = useState(false)
  const inputRef = useRef(null)

  const { control, setValue } = useForm()

  const query = useWatch({
    control,
    name: 'tableSelect'
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tables = await getTables(axiosPrivate)
        setTables(tables)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (tableNr) {
      setValue('tableSelect', tableNr)
      setSelectedTable(tableNr)
    }
  }, [tableNr])

  const filteredTables = useMemo(() => {
    return tables.filter((table) => {
      return table.tableNr === parseInt(query)
    })
  }, [query])

  const renderResults = () => {
    if (openResults) {
      return (
        <div
          className={styles.resultsContainer}
          style={{
            width: inputRef.current?.offsetWidth
          }}
        >
          {filteredTables.length > 0
            ? filteredTables.map((table) => (
                <MenuItem
                  key={table.tableNr}
                  className={styles.result}
                  onClick={() => handleChangeTable(table)}
                >
                  {table.tableNr}
                </MenuItem>
              ))
            : tables.map((table) => (
                <MenuItem
                  key={table.tableNr}
                  className={styles.result}
                  onClick={() => handleChangeTable(table)}
                >
                  {table.tableNr}
                </MenuItem>
              ))}
        </div>
      )
    }
  }

  const handleChangeTable = (table) => {
    if (tableNr) {
      setValue('tableSelect', tableNr)
      setSelectedTable(tableNr)
    } else {
      setValue('tableSelect', table.tableNr)
      setSelectedTable(table.tableNr)
    }
    setOpenResults(false)
  }

  const handleInputFocus = () => {
    setOpenResults(true)
  }

  const handleClickCancel = () => {
    if (!tableNr) {
      setValue('tableSelect', '')
      setSelectedTable(null)
      setOpenResults(false)
    }
  }

  return (
    <div className={styles.container}>
      <CustomTextField
        control={control}
        className={styles.select}
        name={'tableSelect'}
        inputRef={inputRef}
        autoComplete="off"
        onFocus={() => handleInputFocus()}
      />
      {!query ? (
        <IconButton
          className={styles.btn}
          onClick={() => setOpenResults(!openResults)}
        >
          <ArrowDropDownIcon />
        </IconButton>
      ) : (
        <IconButton className={styles.btn} onClick={() => handleClickCancel()}>
          <CancelIcon />
        </IconButton>
      )}

      {renderResults()}
    </div>
  )
}

export default TableSelect
