import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { FormControlLabel, Stack, Switch, Typography, Box } from '@mui/material'

import { getAllCategories, getProduct } from '../../../services/Requests'
import { Loading } from '../../common'
import { updateProduct, deleteProduct } from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Categories from '../../../utils/enums/categories'
import {
  FormContainer,
  ImageUpload,
  CustomRadioGroup,
  CustomSelect,
  CustomTextField,
  FormButton
} from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: Categories.FOOD, value: Categories.FOOD },
  { label: Categories.DRINKS, value: Categories.DRINKS }
]

function AdminEditProduct() {
  const axiosPrivate = useAxiosPrivate()
  const { userName, productId } = useParams()
  const navigate = useNavigate()
  const { showSuccess, showError } = useToast()

  const [subCategories, setSubCategories] = useState(null)
  const [product, setProduct] = useState(null)
  const [isLoading, setLoading] = useState({
    edit: false,
    delete: false
  })

  const { handleSubmit, control, setValue, reset } = useForm()
  const selectedCategory = useWatch({
    control,
    name: 'category'
  })

  const onSubmit = async ({
    name,
    price,
    ingredients,
    weight,
    allergens,
    inStock,
    subCategory,
    image
  }) => {
    try {
      setLoading({ ...isLoading, edit: true })
      await updateProduct(
        axiosPrivate,
        product,
        name,
        price,
        ingredients,
        weight,
        allergens,
        inStock,
        subCategory,
        image
      )

      showSuccess('Product edited successfully')
      navigate(ENDPOINTS.productsList(userName))
    } catch (error) {
      setLoading({ ...isLoading, edit: false })
      showError(`Action failed. Please try again!`)
    }
  }

  const handleDeleteProduct = async () => {
    try {
      setLoading({ ...isLoading, delete: true })
      await deleteProduct(axiosPrivate, productId)
      showSuccess('Product deleted successfully')
      navigate(ENDPOINTS.productsList(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading({ ...isLoading, delete: false })
    }
  }

  const selectOptions = subCategories?.reduce((acc, item) => {
    if (item.bigCategory === selectedCategory) {
      acc.push({
        label: item.smallCategory,
        value: item.smallCategory
      })
    }
    return acc
  }, [])

  useEffect(() => {
    const fetchCategoriesAndProduct = async () => {
      try {
        const categories = await getAllCategories(axiosPrivate)
        let product = await getProduct(axiosPrivate, productId)

        product = {
          ...product,
          bigCategory:
            categories.find((c) => {
              return c.smallCategory === product.category
            }).bigCategory === Categories.FOOD
              ? Categories.FOOD
              : Categories.DRINKS
        }

        setProduct(product)
        setSubCategories(categories)
        reset({
          name: product.name,
          price: product.price,
          ingredients: product.ingredients,
          weight: product.weight,
          allergens: product.allergens,
          subCategory: product.category,
          category: product.bigCategory,
          inStock: product.inStock
        })
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchCategoriesAndProduct()
  }, [])

  const handleCategoryChange = (value) => {
    setValue('subCategory', '')
  }

  if (product === null || subCategories === null) {
    return <Loading />
  }
  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Edit product
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="name"
            label="Name"
            isRequired
          />
          <Box display="flex" gap={2}>
            <CustomTextField
              type="number"
              control={control}
              name="price"
              label="Price"
              isRequired
            />

            <CustomTextField
              control={control}
              name="weight"
              label="Weight"
              placeholder="e.g. 250g"
              isRequired
            />
          </Box>

          <CustomTextField
            control={control}
            name="ingredients"
            label="Ingredients"
            multiline
            rows={2}
            isRequired
          />

          <CustomTextField
            control={control}
            name="allergens"
            label="Allergens"
            multiline
            rows={2}
          />

          <CustomRadioGroup
            control={control}
            name="category"
            label="Category"
            customOnChange={handleCategoryChange}
            options={radioOptions}
            row
            isRequired
          />

          <CustomSelect
            options={selectOptions}
            control={control}
            name="subCategory"
            label="Subcategory"
            isRequired
            disabled={!selectedCategory}
          />

          <Controller
            name="inStock"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label="In stock"
              />
            )}
          />

          <ImageUpload
            control={control}
            name="image"
            initialImage={product.image}
          />

          <FormButton isLoading={isLoading.edit} type={ButtonType.EDIT}>
            <Typography variant="button">Edit product</Typography>
          </FormButton>
        </Stack>
      </form>
      <FormButton
        isLoading={isLoading.delete}
        type={ButtonType.DELETE}
        onClick={() => handleDeleteProduct(userName, productId)}
      >
        <Typography variant="button">Delete product</Typography>
      </FormButton>
    </FormContainer>
  )
}

export default AdminEditProduct
