import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useController } from 'react-hook-form'

const CustomRadioGroup = ({
  control,
  name,
  label,
  options,
  customOnChange,
  defaultValue = '',
  disabled,
  isRequired,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue,
    rules: isRequired ? { required: 'Please select an option' } : undefined
  })

  const handleRadioChange = (event) => {
    if (typeof customOnChange === 'function') {
      customOnChange()
    }
    const selectedValue = event.target.value
    onChange(selectedValue)
  }

  return (
    <div>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          value={value}
          onChange={handleRadioChange}
          onBlur={onBlur}
          {...rest}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        {invalid && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </div>
  )
}

export default CustomRadioGroup
