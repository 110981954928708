import { useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'

import {
  FormContainer,
  CustomTextField,
  CustomRadioGroup,
  ImageUpload,
  FormButton
} from '../../common/CustomFormComponents'
import { addNewSubCategory } from '../../../services/AdminActions'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import Categories from '../../../utils/enums/categories'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

const radioOptions = [
  { label: Categories.FOOD, value: Categories.FOOD },
  { label: Categories.DRINKS, value: Categories.DRINKS }
]

function AdminAddNewCategory() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const navigate = useNavigate()
  const { userName } = useParams()
  const [isLoading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm()

  const onSubmit = async ({ subCategory, category, image }) => {
    try {
      setLoading(true)
      await addNewSubCategory(axiosPrivate, category, subCategory, image)
      showSuccess('Category added successfully')
      navigate(ENDPOINTS.manageCategories(userName))
    } catch (error) {
      showError(`Action failed. Please try again!`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Add new category
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="subCategory"
            label="Category Name"
            isRequired
          />

          <CustomRadioGroup
            control={control}
            name="category"
            label="Category"
            options={radioOptions}
            row
            isRequired
          />

          <ImageUpload control={control} name="image" />

          <FormButton isLoading={isLoading} type={ButtonType.ADD}>
            <Typography variant="button">Add category</Typography>
          </FormButton>
        </Stack>
      </form>
    </FormContainer>
  )
}

export default AdminAddNewCategory
