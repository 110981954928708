import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '../../../context/AuthContext'

const ProtectedRoute = ({ allowedRole }) => {
  const { auth } = useAuth()
  const location = useLocation()
  return allowedRole === auth?.user?.position ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/logout" state={{ from: location }} replace />
  )
}

export default ProtectedRoute
