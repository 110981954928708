import { Outlet } from 'react-router-dom'

import KitchenHeader from '../components/common/Header/KitchenHeader'

const KitchenLayout = () => {
  return (
    <>
      <KitchenHeader />
      <Outlet />
    </>
  )
}

export default KitchenLayout
