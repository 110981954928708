import { Container, Box, Typography, CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <Container
      sx={{
        position: 'fixed',
        top: '100px',
        left: 0,
        right: 0,
        bottom: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1500
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, mb: 3 }}
        >
          Loading...
        </Typography>
        <CircularProgress color="inherit" />
      </Box>
    </Container>
  )
}

export default Loading
