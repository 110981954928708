import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Container, TextField, Typography } from '@mui/material'
import classNames from 'classnames'
import { useQuery } from '@tanstack/react-query'

import { Loading } from '../../common'
import { getParticularProduct } from '../../../services/Requests'
import { useCart } from '../../../context/CartContext'
import NumericUpDown from '../../common/NumericUpDown/NumericUpDown'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { capitalizeString } from '../../../utils/functions/stringUtils'
import { useToast } from '../../../hooks/useToast'
import { getImageSrc } from '../../../utils/functions/images'

import styles from './Product.module.css'

function Product() {
  const axiosPrivate = useAxiosPrivate()
  const { productId, tableNr, category, subcategory } = useParams()
  const {
    increaseCartQuantity,
    adjustCartQuantity,
    decreaseCartQuantity,
    getItemQuantity
  } = useCart()
  const [productComment, setProductComment] = useState('')
  const [quantity, setQuantity] = useState(0)
  const { showError } = useToast()

  const capitalizedCategory = capitalizeString(category)
  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`product`, productId],
    queryFn: () =>
      getParticularProduct(
        axiosPrivate,
        productId,
        capitalizedCategory,
        subcategory
      ),
    staleTime: 2 * 60 * 60 * 1000,
    gcTime: 24 * 60 * 60 * 1000
  })

  useEffect(() => {
    if (data) {
      setQuantity(getItemQuantity(data, productComment, tableNr))
    }
  }, [data, getItemQuantity])

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again or error page
    return <div>Try again,{error.message}</div>
  }

  const handleIncreaseCartQuantity = (data, productComment, tableNr) => {
    try {
      increaseCartQuantity(data, productComment, tableNr)
      setQuantity(quantity + 1)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const handleDecreaseCartQuantity = (data, productComment, tableNr) => {
    try {
      decreaseCartQuantity(data, productComment, tableNr)
      if (quantity > 0) {
        setQuantity(quantity - 1)
      }
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  const handleAdjustCartQuantity = (
    data,
    newQuantity,
    productComment,
    tableNr
  ) => {
    try {
      adjustCartQuantity(data, newQuantity, productComment, tableNr)
      setQuantity(newQuantity)
    } catch (error) {
      showError('Action failed. Please try again!')
    }
  }

  return (
    <Container
      className={classNames(
        styles.container,
        styles.particularProductContainer
      )}
    >
      <div className={styles.productCard}>
        <img src={getImageSrc(data.image)} />
        <div className={styles.productInfo}>
          <div className={styles.productName}>{data.name}</div>
          <div className={styles.productPrice}>{data.price} LEI</div>
        </div>
        <div className={styles.productDescription}>
          <Typography variant="p" color="text.secondary">
            <Typography variant="p" color="text.secondary" fontWeight="bold">
              Ingredients:
            </Typography>{' '}
            {data.ingredients}
            {' - '}
            <Typography variant="span" color="text.secondary" fontWeight="bold">
              {data.weight}
            </Typography>
          </Typography>
          {data.allergens && (
            <Typography variant="p" color="text.secondary">
              <Typography variant="p" color="text.secondary" fontWeight="bold">
                Allergens:
              </Typography>{' '}
              {data.allergens}
            </Typography>
          )}
        </div>
        <div className={styles.commentContainer}>
          <TextField
            id="standard-multiline-static"
            label="Comment"
            color="secondary"
            className={styles.commentInput}
            multiline
            rows={2}
            inputProps={{
              maxLength: 250
            }}
            onChange={(event) => {
              setProductComment(event.target.value)
            }}
          />
        </div>
        <div className={styles.cardActions}>
          <NumericUpDown
            min={1}
            initialValue={quantity}
            onIncrease={() =>
              handleIncreaseCartQuantity(data, productComment, tableNr)
            }
            onDecrease={() =>
              handleDecreaseCartQuantity(data, productComment, tableNr)
            }
            onAdjust={(quantity) =>
              handleAdjustCartQuantity(data, quantity, productComment, tableNr)
            }
          />
        </div>
      </div>
    </Container>
  )
}

export default Product
