import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Link, useParams } from 'react-router-dom'
import { IconButton, MenuItem, Divider } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import classnames from 'classnames'

import TableSelect from '../TableSelect/TableSelect'
import { getProducts } from '../../../services/AdminActions'
import { getAllCategories } from '../../../services/Requests'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import styles from './Search.module.css'

const Search = () => {
  const axiosPrivate = useAxiosPrivate()
  const { userName } = useParams()
  const [selectedTable, setSelectedTable] = useState('')
  const [products, setProducts] = useState([])
  const [query, setQuery] = useState('')
  const [categories, setCategories] = useState([])
  const [clickedOutside, setClickedOutside] = useState(false)
  const searchInputRef = useRef(null)
  const searchResultsRef = useRef(null)

  const filteredData = useMemo(() => {
    const filteredProducts = products.filter((product) => {
      return (
        product.name.toLowerCase().includes(query.toLowerCase()) ||
        product.category.toLowerCase().includes(query.toLowerCase())
      )
    })

    const filteredCategories = categories.filter((category) => {
      return category.smallCategory.toLowerCase().includes(query.toLowerCase())
    })

    return { filteredProducts, filteredCategories }
  }, [query])
  const { filteredProducts, filteredCategories } = filteredData

  const handleAccessItem = (item) => {
    try {
      setQuery(item)
      setClickedOutside(true)
    } catch (error) {
      // error
    }
  }

  const getCategory = (product) => {
    const category = categories.filter((category) => {
      return category.smallCategory === product.category
    })
    return category[0]?.bigCategory.toLowerCase()
  }

  const getProductResultPath = (item, selectedTable) => {
    return `/${userName}/tables/${selectedTable}/${getCategory(item)}/${
      item.category
    }/${item._id}`
  }

  const getCategoryResultPath = (item, selectedTable) => {
    return `/${userName}/tables/${selectedTable}/${item.bigCategory.toLowerCase()}/${
      item.smallCategory
    }`
  }

  const handleClickOutside = (event) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(event.target)
    ) {
      setClickedOutside(true)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getProducts(axiosPrivate)
        const categories = await getAllCategories(axiosPrivate)
        setProducts(products)
        setCategories(categories)
      } catch (error) {
        // setError(error.message)
      }
    }
    fetchData()

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const renderFilteredProducts = () => {
    return filteredProducts.map((item) => (
      <div key={item._id}>
        {selectedTable === '' ? (
          <MenuItem
            key={item._id}
            className={styles.searchResult}
            onClick={() => handleAccessItem(item.name)}
          >
            {item.name}
          </MenuItem>
        ) : (
          <Link
            className={styles.link}
            to={getProductResultPath(item, selectedTable)}
            onClick={() => setQuery('')}
          >
            <MenuItem
              key={item._id}
              className={styles.searchResult}
              onClick={() => handleAccessItem(item.name)}
            >
              {item.name}
            </MenuItem>
          </Link>
        )}
      </div>
    ))
  }
  const renderFilteredCategories = () => {
    return filteredCategories.map((item) => (
      <div key={item._id}>
        {selectedTable === '' ? (
          <MenuItem
            key={item._id}
            className={styles.searchResult}
            onClick={() => handleAccessItem(item.smallCategory)}
          >
            {item.smallCategory}
          </MenuItem>
        ) : (
          <Link
            className={styles.link}
            to={getCategoryResultPath(item, selectedTable)}
            onClick={() => setQuery('')}
          >
            <MenuItem
              key={item._id}
              className={styles.searchResult}
              onClick={() => handleAccessItem(item.smallCategory)}
            >
              {item.smallCategory}
            </MenuItem>
          </Link>
        )}
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.searchContainer, {
          [styles.disabledContainer]: !selectedTable
        })}
      >
        {query === '' ? (
          <img src="/images/search-icon.svg" alt=""></img>
        ) : (
          <IconButton
            aria-label="delete"
            className={styles.cancelBtn}
            size="small"
            onClick={() => setQuery('')}
          >
            <CancelIcon />
          </IconButton>
        )}
        <input
          type="search"
          placeholder={selectedTable ? 'Search...' : 'Pick a table'}
          aria-label="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => {
            setClickedOutside(false)
          }}
          ref={searchInputRef}
          disabled={!selectedTable}
        />
      </div>
      {clickedOutside === false && query !== '' && (
        <div
          className={styles.searchResultsContainer}
          style={{
            width: searchInputRef.current?.offsetWidth,
            left: searchInputRef.current?.offsetLeft
          }}
          ref={searchResultsRef}
        >
          {renderFilteredCategories()}
          <Divider variant="middle" component="li" />
          {renderFilteredProducts()}
        </div>
      )}
      <div className={styles.selectContainer}>
        <TableSelect setSelectedTable={setSelectedTable} />
      </div>
    </div>
  )
}

export default Search
