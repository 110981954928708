import TableStatus from '../enums/tableStatus'

export const getTableStatusImage = (tableStatus) => {
  switch (tableStatus) {
    case TableStatus.FREE:
      return '/images/green-circle.svg'
    case TableStatus.OCCUPIED:
      return '/images/red-circle.svg'
    default:
      return null
  }
}
