import React from 'react'

import { Container, IconButton, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import classNames from 'classnames'

import styles from './Toast.module.css'

const Toast = React.forwardRef((props, ref) => {
  const { message, variant, onClose } = props

  return (
    <Container
      ref={ref}
      className={classNames(styles.toast, styles[`border${variant}`])}
    >
      {variant === 'success' && (
        <CheckCircleRoundedIcon
          color="success"
          className={classNames(styles.toastIcon, styles.iconsuccess)}
        />
      )}
      {variant === 'error' && (
        <ErrorRoundedIcon
          color="error"
          className={classNames(styles.toastIcon, styles.iconerror)}
        />
      )}
      {variant === 'info' && (
        <InfoRoundedIcon
          color="info"
          className={classNames(styles.toastIcon, styles.iconinfo)}
        />
      )}
      <div className={styles.content}>
        <Typography
          className={classNames(styles.text, styles[`text${variant}`])}
          component={'p'}
        >
          {message}
        </Typography>
      </div>
      <IconButton onClick={onClose} size="small" className={styles.button}>
        <CloseRoundedIcon className={styles.toastIcon} />
      </IconButton>
    </Container>
  )
})

export default Toast
