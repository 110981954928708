import { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Button, Typography, Collapse, IconButton, Modal } from '@mui/material'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'

import { OrderModal } from '../../common'
import { freeTable } from '../../../services/WaiterActions'
import {
  getOrderedProductStatusImage,
  returnPrimaryOrderWaiter,
  returnSecondaryOrderWaiters,
  parseOrderTimeAndDate
} from '../../../utils/functions/orders'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

import styles from './Order.module.css'

function Order({ order }) {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const { userName } = useParams()
  const { showError } = useToast()

  const freeTableMutation = useMutation({
    mutationFn: ({ table }) => freeTable(axiosPrivate, table),
    onSuccess: (newOrderData) => {
      const orderId = newOrderData._id

      queryClient.setQueryData(
        ['orders', 'active', userName],
        (oldOrdersData) => {
          return oldOrdersData.map((order) => {
            if (order._id === orderId) {
              return newOrderData
            }
            return order
          })
        }
      )
    },
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const [showMore, setShowMore] = useState(false)
  const [orderViewMode, setOrderViewMode] = useState(false)

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleFreeTable = async (table) => {
    try {
      await freeTableMutation.mutateAsync({
        table
      })
    } catch (error) {
      console.error('Error freeing table: ', error)
      throw error
    }
  }

  const toggleOrderViewMode = async () => {
    setOrderViewMode(!orderViewMode)
  }

  return (
    <div>
      <Modal open={orderViewMode || false}>
        <>
          <OrderModal
            order={order}
            orderViewMode={orderViewMode}
            toggleOrderViewMode={toggleOrderViewMode}
            handleFreeTable={handleFreeTable}
          />
        </>
      </Modal>
      <div
        className={classNames(styles.orderCard, {
          [styles.activeOrder]: order?.activeOrder,
          [styles.inactiveOrder]: !order?.activeOrder
        })}
      >
        <div className={styles.content}>
          <div className={styles.orderHeader}>
            <h5 className={styles.title}>Table {order.table}</h5>
            <IconButton
              type="button"
              size="small"
              onClick={() => toggleOrderViewMode()}
              className={styles.orderViewModeBtn}
            >
              {!orderViewMode ? (
                <OpenInFullIcon color="primary" fontSize="small" />
              ) : (
                <CloseFullscreenIcon color="primary" fontSize="small" />
              )}
            </IconButton>
          </div>
          <div className={styles.orderTime}>
            <div className={styles.createdTime}>
              <span className={styles.orderTimeText}>Created:</span>
              <p className={styles.orderTimeText}>
                {order.activeOrder
                  ? parseOrderTimeAndDate(order.createdAt, 'time')
                  : parseOrderTimeAndDate(order.createdAt, 'default')}
              </p>
            </div>
            {order.closedAt && (
              <div className={styles.closedTime}>
                <span className={styles.orderTimeText}>Closed:</span>
                <p className={styles.orderTimeText}>
                  {order.activeOrder
                    ? parseOrderTimeAndDate(order.closedAt, 'time')
                    : parseOrderTimeAndDate(order.closedAt, 'default')}
                </p>
              </div>
            )}
          </div>
          <div className={styles.waiters}>
            <div className={styles.primaryWaiter}>
              {returnPrimaryOrderWaiter(order)}
              {order.users.length > 1 && (
                <Button
                  className={styles.showMoreBtn}
                  onClick={() => toggleShowMore()}
                >
                  <Typography className={styles.showMoreText} variant="button">
                    Show More
                  </Typography>
                </Button>
              )}
            </div>
            <Collapse in={showMore}>
              {returnSecondaryOrderWaiters(order)}
            </Collapse>
          </div>
          <div className={classNames(styles.products, styles.scrollbar)}>
            {order.products.map((product, index) => {
              return (
                <div key={index} className={styles.product}>
                  <div className={styles.orderedProduct}>
                    <img
                      src={getOrderedProductStatusImage(product.status)}
                      alt={`${product.status}`}
                    />
                    <div className={styles.productInfo}>
                      <p className={styles.productName}>{product.name}</p>
                      <p className={styles.productQuantity}>
                        {product.quantity}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <h6 className={styles.totalPrice}>Total: {order.totalPrice} LEI</h6>
          {order?.activeOrder === true && (
            <Button
              variant="contained"
              color="secondary"
              className={styles.freeTableBtn}
              onClick={() => handleFreeTable(order)}
            >
              <Typography variant="button">Free table</Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Order
