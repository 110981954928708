import { useState, useEffect } from 'react'

import {
  Container,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import classNames from 'classnames'
import AddIcon from '@mui/icons-material/Add'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getTables } from '../../../services/Requests'
import { addNewTable, deleteTable } from '../../../services/AdminActions'
import { Loading, NoContent } from '../../common'
import { AdminTablesHeader } from '../../common/ListsHeaders'
import TableStatus from '../../../utils/enums/tableStatus'
import { getTableStatusImage } from '../../../utils/functions/tables'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

import styles from './AdminTablesList.module.css'

function AdminTablesList() {
  const axiosPrivate = useAxiosPrivate()
  const { showError } = useToast()
  const [sortedTables, setSortedTables] = useState(null)
  const [orderBy, setOrderBy] = useState({})
  const [filterBy, setFilterBy] = useState({})
  const queryClient = useQueryClient()

  const {
    isLoading,
    isError,
    data: tables,
    error
  } = useQuery({
    queryKey: ['tables-admin'],
    queryFn: () => getTables(axiosPrivate)
  })

  useEffect(() => {
    if (!tables) {
      return
    }
    const sortedTables = sortTables(tables, orderBy)
    const filteredTables = filterTables(sortedTables, filterBy)
    setSortedTables(filteredTables)
  }, [orderBy, filterBy, tables])

  const handleAddNewTable = async () => {
    try {
      await addNewTableMutation.mutateAsync()
    } catch (error) {
      console.error('Error freeing table: ', error)
      throw error
    }
  }

  const addNewTableMutation = useMutation({
    mutationFn: () => addNewTable(axiosPrivate),
    onSuccess: (newTableData) => {
      queryClient.setQueryData(['tables-admin'], (oldTablesData) => {
        return [...oldTablesData, newTableData]
      })
    },
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const handleDeleteTable = async (tableId) => {
    try {
      await deleteTableMutation.mutateAsync(tableId)
    } catch (error) {
      console.error('Error deleting table: ', error)
      throw error
    }
  }

  const deleteTableMutation = useMutation({
    mutationFn: (tableId) => deleteTable(axiosPrivate, tableId),
    onSuccess: (newTableData) => {
      queryClient.setQueryData(['tables-admin'], (oldTablesData) => {
        return oldTablesData.filter((table) => table._id !== newTableData._id)
      })
    },
    onError: () => {
      showError('Action failed. Please try again!')
    }
  })

  const sortTables = (tables, orderBy) => {
    const tablesCopy = [...tables]
    return tablesCopy.sort((a, b) => {
      if (orderBy?.criteria === 'name') {
        return orderBy?.order === false
          ? a.tableNr - b.tableNr
          : b.tableNr - a.tableNr
      } else if (orderBy?.criteria === 'status') {
        return orderBy?.order === false
          ? (a.tableStatus === TableStatus.FREE ? -1 : 1) -
              (b.tableStatus === TableStatus.FREE ? -1 : 1)
          : (b.tableStatus === TableStatus.FREE ? -1 : 1) -
              (a.tableStatus === TableStatus.FREE ? -1 : 1)
      }
      return 0
    })
  }

  const filterTables = (tables, filterBy) => {
    if (filterBy?.selected !== null) {
      if (filterBy?.criteria === 'status') {
        return tables.filter(
          (table) =>
            (table.tableStatus === TableStatus.FREE &&
              filterBy?.selected === 'Free') ||
            (table.tableStatus === TableStatus.OCCUPIED &&
              filterBy?.selected === 'Occupied')
        )
      } else {
        return tables
      }
    } else {
      return tables
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    return <div>Try again,{error.message}</div>
  }

  return (
    <Container className={styles.container}>
      <Box display={'flex'} mt={4}>
        <Button
          variant="contained"
          onClick={() => handleAddNewTable()}
          startIcon={<AddIcon />}
        >
          <Typography variant="button">Add new table</Typography>
        </Button>
      </Box>
      {tables?.length ? (
        <>
          <AdminTablesHeader
            setOrderByParam={setOrderBy}
            setFilterByParam={setFilterBy}
          />
          <List sx={{ pt: 0 }}>
            {sortedTables?.map((value, index) => {
              const labelId = `tables-label-${index}`

              return (
                <ListItem
                  sx={{
                    backgroundColor: 'background.paper',
                    py: 1,
                    mt: 1,
                    borderRadius: 4
                  }}
                  key={value._id}
                  style={{
                    border: 'none',
                    padding: 'none',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    display={'flex'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box display={'flex'} gap={2} width={'45%'}>
                      <img
                        src={getTableStatusImage(value.tableStatus)}
                        alt={`${value.tableStatus}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          overflow: 'clip'
                        }}
                        id={labelId}
                        primary={`Masa ${value.tableNr}`}
                      />
                    </Box>
                    <Box display={'flex'}>
                      {value.tableStatus === TableStatus.FREE ? (
                        <Typography
                          sx={{
                            color: 'secondary',
                            fontSize: '0.8rem',
                            marginTop: '4px',
                            marginBottom: '4px'
                          }}
                        >
                          Free
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: 'secondary',
                            fontSize: '0.8rem',
                            marginTop: '4px',
                            marginBottom: '4px'
                          }}
                        >
                          Occupied
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      {value.tableStatus === TableStatus.FREE &&
                        parseInt(value.tableNr, 10) === tables.length && (
                          <button
                            type="button"
                            className={classNames(
                              styles.btn,
                              styles.disabledBtn
                            )}
                            style={{
                              width: 'auto',
                              height: '27px',
                              margin: '0',
                              display: 'flex',
                              padding: '0'
                            }}
                            onClick={() => handleDeleteTable(value._id)}
                          >
                            <img
                              style={{
                                width: 'auto',
                                height: '25px',
                                margin: '0',
                                padding: '0'
                              }}
                              src="/images/minus-red-icon.png"
                              alt={`${value.tableStatus}`}
                            />
                          </button>
                        )}
                      {(parseInt(value.tableNr, 10) !== tables.length ||
                        value.tableStatus === TableStatus.OCCUPIED) && (
                        <button
                          type="button"
                          disabled={true}
                          className={classNames(styles.btn, styles.disabledBtn)}
                          style={{
                            width: 'auto',
                            height: '27px',
                            margin: '0',
                            display: 'flex',
                            padding: '0'
                          }}
                        >
                          <img
                            style={{
                              width: 'auto',
                              height: '25px',
                              margin: '0',
                              padding: '0'
                            }}
                            src="/images/minus-grey-icon.png"
                            alt={`${value.tableStatus}`}
                          />
                        </button>
                      )}
                    </Box>
                  </Box>
                </ListItem>
              )
            })}
          </List>
        </>
      ) : (
        <NoContent text={'There are no tables in the database'} />
      )}
    </Container>
  )
}

export default AdminTablesList
