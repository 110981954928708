import axios from 'axios'

import {
  DEFAULT_PRODUCT_IMAGE,
  DEFAULT_CATEGORY_IMAGE,
  DEFAULT_USER_IMAGE
} from '../utils/entities/constants'
import { ENDPOINTS } from '../utils/entities/urls'
import TableStatus from '../utils/enums/tableStatus'

export const addNewTable = async (axiosInstance) => {
  try {
    const newTable = {
      tableNr: 0,
      tableStatus: TableStatus.FREE
    }

    const tables = await axiosInstance.get(ENDPOINTS.tables())
    if (tables.data.tables.length === 0) {
      newTable.tableNr = 1
    } else {
      newTable.tableNr = tables.data.tables.length + 1
    }
    const response = await axiosInstance.post(ENDPOINTS.tables(), newTable)
    return response.data.table
  } catch (e) {
    console.error('Add new table error: ', e)
    throw e
  }
}

export const deleteTable = async (axiosInstance, tableId) => {
  try {
    const response = await axiosInstance.delete(ENDPOINTS.tableId(tableId))
    return response.data.deletedTable
  } catch (e) {
    console.error('Delete table error: ', e)
    throw e
  }
}

export const getProducts = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.products())
    return response.data.products
  } catch (error) {
    console.error('Error getting the products', error)
    throw error
  }
}

export const addNewProduct = async (
  axiosInstance,
  name,
  price,
  ingredients,
  weight,
  allergens,
  smallCategory,
  imageUploaded
) => {
  try {
    const productImage = imageUploaded
      ? await uploadImage(axiosInstance, imageUploaded)
      : DEFAULT_PRODUCT_IMAGE

    const newProduct = {
      name,
      category: smallCategory,
      ingredients,
      weight,
      allergens,
      price,
      image: productImage
    }
    await axiosInstance.post(ENDPOINTS.products(), newProduct)
  } catch (e) {
    console.error('Add new product error: ', e)
    throw e
  }
}

export const updateProduct = async (
  axiosInstance,
  product,
  name,
  price,
  ingredients,
  weight,
  allergens,
  inStock,
  smallCategory,
  imageUploaded
) => {
  try {
    let newImage = product.image
    if (imageUploaded) {
      removeImage(axiosInstance, product.image)
      newImage = await uploadImage(axiosInstance, imageUploaded)
    }

    const updatedProduct = {
      name,
      category: smallCategory,
      price,
      ingredients,
      weight,
      allergens,
      inStock,
      image: newImage
    }
    await axiosInstance.patch(ENDPOINTS.product(product._id), updatedProduct)
  } catch (e) {
    console.error('Update product error: ', e)
    throw e
  }
}

export const deleteProduct = async (axiosInstance, productId) => {
  try {
    await axiosInstance.delete(ENDPOINTS.product(productId))
  } catch (e) {
    console.error('Delete product error: ', e)
    throw e
  }
}

export const addNewSubCategory = async (
  axiosInstance,
  bigCategory,
  smallCategory,
  imageUploaded
) => {
  try {
    const newCategoryImage = imageUploaded
      ? await uploadImage(axiosInstance, imageUploaded)
      : DEFAULT_CATEGORY_IMAGE

    const newCategory = {
      bigCategory,
      smallCategory,
      image: newCategoryImage
    }
    await axiosInstance.post(ENDPOINTS.category(), newCategory)
  } catch (e) {
    console.error('Add new category error: ', e)
    throw e
  }
}

export const updateSubCategory = async (
  axiosInstance,
  categoryObj,
  category,
  subCategory,
  imageUploaded
) => {
  try {
    let newImage = categoryObj.image
    if (imageUploaded) {
      removeImage(axiosInstance, categoryObj.image)
      newImage = await uploadImage(axiosInstance, imageUploaded)
    }
    const updatedCategory = {
      bigCategory: category,
      smallCategory: subCategory,
      image: newImage
    }

    await axiosInstance.patch(
      ENDPOINTS.categoryId(categoryObj.smallCategory),
      updatedCategory
    )
  } catch (e) {
    console.error('Update category error: ', e)
    throw e
  }
}

export const deleteSubCategory = async (axiosInstance, subCategory) => {
  try {
    await axiosInstance.delete(ENDPOINTS.categoryId(subCategory))
  } catch (e) {
    console.error('Delete category error: ', e)
    throw e
  }
}

export const moveProductsToAnotherCategory = async (
  axiosInstance,
  oldCategory,
  newCategory
) => {
  const body = {
    newCategory
  }
  try {
    await axiosInstance.post(
      ENDPOINTS.moveProductsToAnotherCategory(oldCategory),
      body
    )
  } catch (e) {
    console.error('Move products to another category error: ', e)
    throw e
  }
}

export const getUsers = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.users())
    return response.data.users
  } catch (error) {
    console.error('Error getting the users', error)
    throw error
  }
}

export const addNewUser = async (
  axiosInstance,
  name,
  password,
  role,
  userImage
) => {
  try {
    const newUserImage = userImage
      ? await uploadImage(axiosInstance, userImage)
      : DEFAULT_USER_IMAGE

    const newUser = {
      name,
      password,
      position: role,
      image: newUserImage
    }
    await axiosInstance.post(ENDPOINTS.users(), newUser)
  } catch (e) {
    console.error('Add new user error: ', e)
    throw e
  }
}

export const updateUser = async (
  axiosInstance,
  user,
  name,
  password,
  role,
  imageUploaded
) => {
  try {
    let newImage = user.image
    if (imageUploaded) {
      removeImage(axiosInstance, user.image)
      newImage = await uploadImage(axiosInstance, imageUploaded)
    }
    let updatedUser = {
      name,
      password,
      position: role,
      image: newImage
    }

    // TODO : add password validation
    if (updatedUser.password) {
      await axiosInstance.patch(ENDPOINTS.employee(user._id), updatedUser)
    } else {
      updatedUser = { name, position: role, image: newImage }
      await axiosInstance.patch(ENDPOINTS.user(user._id), updatedUser)
    }
  } catch (e) {
    console.error('Update user error: ', e)
    throw e
  }
}

export const updateAdmin = async (
  axiosInstance,
  user,
  name,
  password,
  imageUploaded
) => {
  try {
    let newImage = user.image
    if (imageUploaded) {
      await removeImage(axiosInstance, user.image)
      newImage = await uploadImage(axiosInstance, imageUploaded)
    }
    if (password) {
      const newUser = {
        name,
        password,
        image: newImage
      }

      await axiosInstance.patch(ENDPOINTS.employee(user._id), newUser)
    } else {
      const newUser = { name, image: newImage }

      await axiosInstance.patch(ENDPOINTS.user(user._id), newUser)
    }
  } catch (e) {
    console.error('Update user error: ', e)
    throw e
  }
}

export const deleteUser = async (axiosInstance, userId) => {
  try {
    await axiosInstance.delete(ENDPOINTS.user(userId))
  } catch (e) {
    console.error('Delete user error: ', e)
    throw e
  }
}

const uploadImage = async (axiosInstance, image) => {
  const key =
    Date.now().toString() +
    '.' +
    image.name.substring(image.name.lastIndexOf('.') + 1)
  console.log(image)
  const uploadUrl = await axiosInstance.post(ENDPOINTS.getUploadUrl(), {
    key,
    contentType: image.type
  })

  await axios.put(uploadUrl.data.url, image, {
    headers: {
      'Content-Type': image.type
    }
  })

  return key
}

const removeImage = async (axiosInstance, image) => {
  await axiosInstance.post(ENDPOINTS.deleteImage(image))
}
