import React from 'react'

import { Pagination, Stack } from '@mui/material'

import styles from './Pagination.module.css'

const PaginationComponent = ({ page, totalPages, onPageChange }) => {
  return (
    <div className={styles.pagination}>
      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={onPageChange}
          variant="outlined"
          shape="rounded"
          color="secondary"
        />
      </Stack>
    </div>
  )
}

export default PaginationComponent
