import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Container, Box, Grid, Typography } from '@mui/material'

import { Loading, NoContent, NotFound } from '../../common'
import { getSubCategories } from '../../../services/Requests'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { capitalizeString } from '../../../utils/functions/stringUtils'
import { getImageSrc } from '../../../utils/functions/images'

import styles from './SubCategories.module.css'

function SubCategories() {
  const axiosPrivate = useAxiosPrivate()
  const { userName, tableNr, category } = useParams()

  const capitalizedCategory = capitalizeString(category)
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['subcategories', category],
    queryFn: () => getSubCategories(axiosPrivate, capitalizedCategory),
    staleTime: 2 * 60 * 60 * 1000,
    gcTime: 24 * 60 * 60 * 1000
  })

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    // replace with try again page
    if (error.response.status === 400) {
      return <NotFound />
    }
    return <div>Try again,{error.message}</div>
  }
  if (data?.length === 0) {
    return <NoContent text={'There are no subcategories for this category'} />
  }

  return (
    <Container className={styles.container}>
      <Grid container spacing={2}>
        {data.map((value) => {
          return (
            <Grid item lg={3} md={4} sm={4} xs={6} key={value._id}>
              <Box className={styles.card}>
                <Link
                  to={`/${userName}/tables/${tableNr}/${category}/${value.smallCategory}`}
                >
                  <img
                    className={styles.cardImg}
                    src={getImageSrc(value.image)}
                  />
                  <Box>
                    <Typography
                      pt={2}
                      pb={2}
                      component={'h2'}
                      variant="h5"
                      textAlign={'center'}
                      fontWeight={'500'}
                      fontSize={'1.3rem'}
                    >
                      {value.smallCategory}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default SubCategories
