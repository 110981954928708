import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'

import { updateAdmin } from '../../../services/AdminActions'
import { getUserByName } from '../../../services/Requests'
import { Loading } from '../../common'
import { ENDPOINTS } from '../../../utils/entities/urls'
import ButtonType from '../../../utils/enums/buttonType'
import {
  FormContainer,
  ImageUpload,
  CustomTextField,
  FormButton
} from '../../common/CustomFormComponents'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useToast } from '../../../hooks/useToast'

function AdminProfile() {
  const axiosPrivate = useAxiosPrivate()
  const { showSuccess, showError } = useToast()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const { userName } = useParams()
  const [isLoading, setLoading] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    setError: setFieldError,
    clearErrors
  } = useForm()

  const password = watch('password')
  const confirmPassword = watch('cpassword')

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setFieldError('cpassword', {
        type: 'manual',
        message: 'Passwords do not match'
      })
    } else {
      clearErrors('cpassword')
    }
  }, [password, confirmPassword, setFieldError, clearErrors])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getUserByName(axiosPrivate, userName)
        setUser(user)
      } catch (error) {
        // setError(error.message)
      }
    }

    fetchUser()
  }, [])

  const onSubmit = async ({ name, password, image }) => {
    try {
      setLoading(true)
      await updateAdmin(axiosPrivate, user, name, password, image)
      showSuccess('Admin details updated successfully')
      navigate(ENDPOINTS.logout())
    } catch (error) {
      showError('Action failed. Please try again!')
    } finally {
      setLoading(false)
    }
  }

  if (user === null) {
    return <Loading />
  }
  return (
    <FormContainer>
      <Typography
        variant="h4"
        component="h1"
        fontWeight={700}
        align="center"
        mt={4}
        mb={2}
      >
        Update Admin Details
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField
            control={control}
            name="name"
            label="Name"
            isRequired
            defaultValue={user.name}
          />

          <CustomTextField
            type="password"
            control={control}
            name="password"
            label="New Password"
            rules={{
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long'
              }
            }}
          />

          <CustomTextField
            type="password"
            control={control}
            name="cpassword"
            label="Confirm New Password"
          />

          <ImageUpload
            control={control}
            name="image"
            initialImage={user.image}
          />

          <FormButton isLoading={isLoading.edit} type={ButtonType.ADD}>
            <Typography variant="button">Edit user</Typography>
          </FormButton>
        </Stack>
      </form>
    </FormContainer>
  )
}

export default AdminProfile
