import { useSnackbar } from 'notistack'

import { Toast } from '../components/common'

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const showInfo = (message, options = {}) => {
    enqueueSnackbar(message, {
      variant: 'info',
      ...options,
      content: (key, message) => (
        <Toast
          key={key}
          message={message}
          variant="info"
          onClose={() => closeSnackbar(key)}
        />
      )
    })
  }

  const showSuccess = (message, options = {}) => {
    enqueueSnackbar(message, {
      variant: 'success',
      ...options,
      autoHideDuration: 3000,
      content: (key, message) => (
        <Toast
          key={key}
          message={message}
          variant="success"
          onClose={() => closeSnackbar(key)}
        />
      )
    })
  }

  const showError = (message, options = {}) => {
    enqueueSnackbar(message, {
      variant: 'error',
      ...options,
      content: (key, message) => (
        <Toast
          key={key}
          message={message}
          variant="error"
          onClose={() => closeSnackbar(key)}
        />
      )
    })
  }

  return { showInfo, showSuccess, showError }
}
