import React, { useEffect, useState } from 'react'

import {
  IconButton,
  Typography,
  TextField,
  Autocomplete,
  Collapse
} from '@mui/material'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import classNames from 'classnames'

import styles from './TablesHeader.module.css'

const statusOptions = ['Free', 'Occupied']

function TablesHeader({ setOrderByParam, setFilterByParam }) {
  const [orderBy, setOrderBy] = useState({
    criteria: 'name',
    order: false
  })
  const [filterBy, setFilterBy] = useState({
    criteria: 'status',
    selected: null
  })
  const [showFilters, setShowFilters] = useState(false)

  const handleClickSubCategoryFilter = () => {
    handleSortChange('name')
  }

  const handleClickCategoryFilter = () => {
    handleSortChange('status')
  }

  const handleSortChange = (criteria) => {
    setOrderBy((prevState) => ({
      criteria,
      order: criteria === prevState.criteria ? !prevState.order : false
    }))
  }

  const handleFilterChange = (event, filterValue) => {
    setFilterBy(() => ({
      criteria: 'status',
      selected: filterValue
    }))
  }

  const toggleShowFilters = () => {
    setShowFilters(!showFilters)
  }

  useEffect(() => {
    setOrderByParam(orderBy)
    setFilterByParam(filterBy)
  }, [orderBy, filterBy])

  return (
    <div className={styles.productsHeaderContainer}>
      <div className={styles.productsHeader}>
        <IconButton
          onClick={() => handleClickSubCategoryFilter()}
          className={classNames(styles.subCategoryButton, [
            orderBy.criteria === 'name' && styles.lastPressed
          ])}
        >
          {orderBy.criteria === 'name' &&
            (orderBy.order ? (
              <NorthIcon fontSize="small" className={styles.icon} />
            ) : (
              <SouthIcon fontSize="small" className={styles.icon} />
            ))}
          <Typography className={styles.text}>Name</Typography>
        </IconButton>
        <IconButton
          onClick={() => handleClickCategoryFilter()}
          className={classNames(styles.categoryButton, [
            orderBy.criteria === 'status' && styles.lastPressed
          ])}
        >
          {orderBy.criteria === 'status' &&
            (orderBy.order ? (
              <NorthIcon fontSize="small" className={styles.icon} />
            ) : (
              <SouthIcon fontSize="small" className={styles.icon} />
            ))}
          <Typography className={styles.text}>Status</Typography>
        </IconButton>
        <IconButton
          onClick={() => toggleShowFilters()}
          className={styles.filterButton}
        >
          {showFilters ? (
            <FilterAltOffIcon fontSize="small" className={styles.icon} />
          ) : (
            <FilterAltIcon fontSize="small" className={styles.icon} />
          )}
        </IconButton>
      </div>
      <Collapse in={showFilters}>
        <div className={styles.filtersContainer}>
          <Autocomplete
            className={styles.autocomplete}
            options={statusOptions}
            onChange={handleFilterChange}
            renderInput={(params) => (
              <TextField {...params} label="Filter by status" />
            )}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default TablesHeader
